import React, { useState, useEffect } from "react";
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import { 
    Row, 
    Col, 
    Card, 
    CardBody,
    Nav,
    NavItem,
    CardTitle,
    NavLink,
    TabPane,
    TabContent,
    CardText,
    Collapse,
    Table,
    UncontrolledAlert,
    Alert,
    Form, UncontrolledDropdown, DropdownToggle, DropdownMenu
} from "reactstrap";

import SimpleBar from "simplebar-react";

import BootstrapTable, { ColumnDescription, PaginationOptions } from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';

import CardNotification from "./card-notification";

import classnames from "classnames";

import { map } from "lodash";
import CardGoGreen from "./card-gogreen";
import CardSMS from "./card-sms";
import { useSelector, useDispatch } from "react-redux";
import {GetEdocValue, GetSMSValue, GetPaymentsValue, GetNotificationCounter} from '../../helpers/global_data_helper';

//Import images
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import tree from "../../assets/images/tree.jpg";
import smsPic from "../../assets/images/sms.jpg";
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import PerfectScrollbar from "react-perfect-scrollbar";
import {InstalApp} from '../../helpers/ipapi_helpers'

const CardDetail = props => {

  const location = useLocation();
  const [queryParameters] = useSearchParams();

  const fakeData = [
      {CustomerName: 'Tony Stark', PhoneNumber: '7864563219'},
      {CustomerName: 'Pepper Potts', PhoneNumber: ''}
  ]

  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(true);
  const [col5, setcol5] = useState(false);

  const toggleVertical = tab => {

      if (verticalActiveTab !== tab) {
          setverticalActiveTab(tab);
      }
  };

  useEffect(() => {
    let tabSelected = queryParameters.get('tab');

    if(tabSelected){
      toggleVertical(tabSelected);
    }
  }, [location]);

  const t_col1 = () => {
      setcol1(!col1);
  };

  const t_col2 = () => {
      setcol2(!col2);
  };

  const t_col5 = () => {
  setcol5(!col5);
  };

  const [selectedFiles, setselectedFiles] = useState([]);

  const columns = [{
      dataField: 'id',
      //text: 'CustomerId'
      }, {
      dataField: 'customerName',
      //text: 'Product Name',
      // Product Name column can't be edit anymore
          editable: false
      }, {
      dataField: 'phoneType',
      //text: 'Product Price'
  }];

  function handleAcceptedFiles(files) {
      files.map(file =>
          Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
          })
      )
      setselectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes"
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const { globalData } = useSelector(state => ({
    globalData: state.Login.globalData
  }));

  const policies = globalData.policies;
  let edoc = GetEdocValue(policies);
  let sms = GetSMSValue(policies);
  let paymentNotifications = GetPaymentsValue(policies);

  // const validation = useFormik({
  //   // enableReinitialize : use this flag when initial values needs to be changed
  //   enableReinitialize: true,

  //   initialValues: {
  //     policynumber: '',
  //     firstname: 'Mark',
  //     lastname: 'Otto',
  //     zip: 'Zip',
  //   },
  //   validationSchema: Yup.object({
  //     policynumber: Yup.string().required("Please provide a policy number"),
  //     firstname: Yup.string().required("Please provide your First Name"),
  //     lastname: Yup.string().required("Please provide your Last Name"),
  //     zip: Yup.string().required("Please Enter Your Zip"),
  //   }),
  //   onSubmit: (values) => {
  //     console.log("values", values);
  //   }
  // });

  return (
    <React.Fragment>
        <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Policy # OICF0014679-01</CardTitle>
                  <p className="card-title-desc">
                      <a className="anchor-style">Email Us <i className="mdi mdi-send" /></a> | <a className="anchor-style">Print Evidence <i className="dripicons-download me-1" /></a> | <a className="anchor-style">View Claims <i className="mdi mdi-clipboard-edit-outline me-1" /></a> | <a className="anchor-style">Upload Documents <i className="dripicons-upload" /></a>
                  </p> */}
                  <Row>
                    <Col md="2">
                      <Nav pills className="flex-column">
                        {/* <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "mb-2": true,
                              active: verticalActiveTab === "1",
                            })}
                            onClick={() => {
                              toggleVertical("1");
                            }}
                          >
                            <i className="bx bx-message-square-dots" /> SMS Test Notification
                          </NavLink>
                        </NavItem> */}
                        {/* <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "mb-2": true,
                              active: verticalActiveTab === "2",
                            })}
                            onClick={() => {
                              toggleVertical("2");
                            }}
                          >
                            <i className="mdi mdi-leaf" /> Go Green
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "mb-2": true,
                              active: verticalActiveTab === "3",
                            })}
                            onClick={() => {
                              toggleVertical("3");
                            }}
                          >
                            <i className="bx bxs-contact" /> Contact Information
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: verticalActiveTab === "4",
                            })}
                            onClick={() => {
                              toggleVertical("4");
                            }}
                          >
                            <i className="bx bx-user" /> Agent
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: verticalActiveTab === "5",
                            })}
                            onClick={() => {
                              toggleVertical("5");
                            }}
                          >
                            <i className="bx bx-money" /> Mortgage
                          </NavLink>
                        </NavItem> */}
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: verticalActiveTab === "1",
                            })}
                            onClick={() => {
                              toggleVertical("1");
                            }}
                          >
                            <i className="mdi mdi-shield-key-outline" /> My Profile
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: verticalActiveTab === "2",
                            })}
                            onClick={() => {
                              toggleVertical("2");
                            }}
                          >
                            <i className="bx bx-bell" /> Notifications
                          </NavLink>
                        </NavItem>
                        {/* <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: verticalActiveTab === "7",
                            })}
                            onClick={() => {
                              toggleVertical("7");
                            }}
                          >
                            <i className="mdi mdi-file-document-multiple-outline" /> Manage Policies
                          </NavLink>
                        </NavItem> */}
                      </Nav>
                    </Col>
                    <Col md="10">
                      <TabContent
                        activeTab={verticalActiveTab}
                        className="text-muted mt-4 mt-md-0"
                      >
                        {/* <TabPane tabId="1">
                        <div>
                            <Row>
                              <Card className="card-grey">
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">Manage Text Notification (SMS)</h5>
                                        
                                    </div>
                                    <p className="mb-1">Message frecuency varies, message and data rates may apply</p>
                                </CardBody>
                                <CardBody>
                                    <div className="accordion" id="accordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    className={classnames(
                                                    "accordion-button",
                                                    "fw-medium",
                                                    { collapsed: !col1 }
                                                    )}
                                                    type="button"
                                                    onClick={t_col1}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                 <i className="mdi mdi-script-text-outline me-1" /> 8375 Dix Ellis Trail Ste 300, Jacksonville, FL 32256 &emsp; &#x2022; OICF0014679-01
                                                </button>
                                            </h2>

                                            <Collapse isOpen={col1} className="accordion-collapse">
                                                <div className="accordion-body">
                                                    <Row>
                                                        {map(fakeData, (data, key) => (
                                                            <CardNotification data={data} key={"_data_" + key} />
                                                        ))}
                                                    </Row>
                                                </div>
                                            </Collapse>
                                        </div>
                                    </div>
                                </CardBody>
                              </Card>  
                            </Row>
                          </div>
                        </TabPane> */}
                        {/* <TabPane tabId="2">
                          <CardGoGreen/>
                        </TabPane>
                        <TabPane tabId="3">
                          
                        </TabPane>

                        <TabPane tabId="4">
                          
                        </TabPane>
                        <TabPane tabId="5">
                          
                        </TabPane> */}
                        <TabPane tabId="1">
                          {/* <div className="flex-grow-1">
                            <div className="d-flex">

                              <div className="flex-grow-1">
                                
                              </div>

                              <UncontrolledDropdown
                                className="ms-2"
                              >
                                <DropdownToggle
                                  className="btn btn-light btn-sm"
                                  color="#eff2f7"
                                  type="button"
                                >
                                  <i className="bx bxs-cog align-middle me-1"></i> Actions
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <Link className="dropdown-item" onClick={() => setcol5(true)} to="#">
                                    Add new Policy
                                  </Link>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                          <hr /> */}
                          <CardGoGreen/>
                          <CardSMS/>
                        </TabPane>
                        <TabPane tabId="2">

                        <div>
                            {/* <h5 className="font-size-14 mb-3">History</h5> */}
                            <ul className="list-unstyled chat-list" id="recent-list">
                              <PerfectScrollbar style={{ height: "410px" }}>
                                <div className="d-flex">
                                  <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="text-truncate font-size-14 mb-1" style={{textAlign: 'center', color: 'red', fontWeight: 'bold'}}>
                                      {props.t("*** Important ***")}
                                    </h5>
                                    <p className="text-truncate mb-0" style={{fontWeight: 'bold'}}>
                                      {props.t(
                                        "Please remember your Olympus homeowners policy does not cover flood. If you have placed your flood coverage with one of our flood carrier partners, please click the appropriate link below to report a flood claim."
                                      )}
                                    </p>
                                    <Link className="btn btn-sm btn-link font-size-14 text-center" target="_blank" to="https://neptuneflood.com/report-a-claim/">
                                        <i className="mdi mdi-open-in-new me-1"></i> <span key="t-view-more">{props.t("Neptune Flood")}</span>
                                    </Link>
                                    <Link className="btn btn-sm btn-link font-size-14 text-center" target="_blank" to="https://bfolympus.manageflood.com/">
                                        <i className="mdi mdi-open-in-new me-1"></i> <span key="t-view-more">{props.t("National Flood (NFIP)")}</span>
                                    </Link>
                                  </div>
                                </div>
                                {!edoc && 
                                  <li>
                                    <Link
                                      to="/settings?tab=1"
                                      // className="cursor-default"
                                    >
                                      <div className="d-flex">
                                        {
                                        // chat.isImg ?
                                        //   <div className="avatar-xs align-self-center me-3">
                                        //     <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                                        //       {chat.profile}
                                        //     </span>
                                        //   </div>
                                        //   :
                                          // <div className="align-self-center me-3">
                                          //   <img
                                          //     src={tree}
                                          //     className="rounded-circle avatar-xs"
                                          //     alt=""
                                          //   />
                                          // </div>
                                        }

                                        <div className="align-self-center me-3">
                                          <img
                                            src={tree}
                                            className="rounded-circle avatar-xs"
                                            alt=""
                                          />
                                        </div>

                                        <div className="flex-grow-1 overflow-hidden">
                                          <h5 className="text-truncate font-size-14 mb-1">
                                            {props.t("Go Green - Go Paperless")}
                                          </h5>
                                          <p className="text-truncate mb-0">
                                            {props.t(
                                              "Lets protect our planet"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                }
                                {!sms && 
                                  <li>
                                    <Link
                                      to="/settings?tab=1"
                                      // className="cursor-default"
                                    >
                                      <div className="d-flex">

                                        <div className="align-self-center me-3">
                                          <img
                                            src={smsPic}
                                            className="rounded-circle avatar-xs"
                                            alt=""
                                          />
                                        </div>

                                        <div className="flex-grow-1 overflow-hidden">
                                          <h5 className="text-truncate font-size-14 mb-1">
                                            {props.t("SMS Text Notification")}
                                          </h5>
                                          <p className="text-truncate mb-0">
                                            {props.t(
                                              "Manage Text Notification"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                }
                                {paymentNotifications.length > 0 &&  
                                  <li>
                                    <Link
                                      to="/account"
                                      // className="cursor-default"
                                    >
                                      <div className="d-flex">
                                        <div className="avatar-xs align-self-center me-3">
                                          <span className="avatar-title rounded-circle bg-danger bg-soft text-primary">
                                            <i className="bx bx-dollar" />
                                          </span>
                                        </div>

                                        <div className="flex-grow-1 overflow-hidden">
                                          <h5 className="text-truncate font-size-14 mb-1">
                                           {props.t("Pending payments")}
                                          </h5>
                                          <p className="text-truncate mb-0">
                                            {
                                              paymentNotifications.map((paymentNotif, index, array) => {

                                                return (<p className="mb-1" key={index}>
                                                  {paymentNotif.policy}{" "} => {" "}{paymentNotif.paymentDue}
                                                </p>)
                                              })
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                }
                                {window['deferredPrompt'] && 
                                  <li>
                                    <Link
                                      onClick={InstalApp}
                                      to="#"
                                      // className="cursor-default"
                                    >
                                      <div className="d-flex">
                                        <div className="avatar-xs align-self-center me-3">
                                          <span className="avatar-title rounded-circle bg-success bg-soft text-primary">
                                            <i className="mdi mdi-application-cog" />
                                          </span>
                                        </div>

                                        <div className="flex-grow-1 overflow-hidden">
                                          <h5 className="text-truncate font-size-14 mb-1">
                                            {props.t("Install Application")}
                                          </h5>
                                          <p className="text-truncate mb-0">
                                            {props.t(
                                              "Get the native application"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                }
                                
                              </PerfectScrollbar>
                            </ul>
                          </div>
                        </TabPane>
                        {/* <TabPane tabId="7">
                          
                        </TabPane> */}
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
    </React.Fragment>
  )
}

export default withTranslation()(CardDetail);

CardDetail.propTypes = {
  t: PropTypes.any
};
