import React from "react";
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

//Import Image
import tree from "../../assets/images/tree.jpg";
import { goGreen, setGlobalData } from "../../store/actions";
import { toggleLoading } from "../../store/actions";

import {GetGlobalData, ShowNotification, myDispatch} from '../../helpers/global_data_helper';
import {GetEdocValue, corpoSiteUrl} from '../../helpers/global_data_helper';

const CardGoGreen = props => {

    const { globalData } = useSelector(state => ({
        globalData: state.Login.globalData
    }));

    // const contactData = globalData.currentPolicy.contactData[0];
    const usertData = globalData.userData[0];
    // const policyData = globalData.currentPolicy.policyData[0];
    const dispatch = useDispatch();

    const policies = globalData.policies;
    let edoc = GetEdocValue(policies);

    const handleGoGreen = (e) => {

        if(e.target.id === 'goGreenSignMeUp'){
            dispatch(goGreen(1, usertData.username, globalData));
            // ShowNotification({type: 'info', message: 'You are signed up for paperless.'});
        }else{
            dispatch(goGreen(0, usertData.username, globalData));
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col lg="12" >
                    <Card>
                        <div className="bg-success bg-soft"> 
                            <CardBody>
                                {/* <div className="d-flex align-start mb-3">
                                    <div className="flex-grow-1">
                                        <span className={item.type === "Full Time" ? "badge badge-soft-success" : item.type === "Freelance" ? "badge badge-soft-info" : item.type === "Part Time" ? "badge badge-soft-danger" : "badge badge-soft-warning"}>
                                            Elem1
                                        </span>
                                    </div>
                                    <button type="button" className="btn btn-light btn-sm like-btn" onClick={(e) => activeBtn(e.target)}>
                                        <i className="bx bx-heart"></i>
                                    </button>
                                </div> */}
                                <div className="text-center mb-3">
                                    <img src={tree} alt="" className="avatar-sm rounded-circle" />
                                    <h6 className="font-size-15 mt-3 mb-1 text-primary">Go Green - Go Paperless</h6>
                                    <p className="mb-0 text-primary">Lets protect our <i className="mdi mdi-earth me-2"></i></p>
                                </div>
                                <div className="text-center mb-3">
                                    <h5>Manage your Electronic Mail delivery:</h5>
                                    <p className="mb-0 text-muted">By selecting “Sign Me Up” you will receive your policy information via email and through this electronic portal. Not by mail.</p>
                                    {/* <p>{contactData.pC_address1}, {contactData.pC_city}, {contactData.pC_state} {contactData.pC_zip}</p>
                                    <p>&emsp; &#x2022; Policy # {policyData.pD_PolicyCode}</p> */}
                                </div>
                                <div className="d-flex mb-3 justify-content-center gap-2 text-muted">
                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="goGreenRadios"
                                            id="goGreenSignMeUp"
                                            value="option1"
                                            defaultChecked={edoc}
                                            onChange={handleGoGreen}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="goGreenSignMeUp"
                                        >
                                            Sign me up!
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="goGreenRadios"
                                            id="goGreenNo"
                                            value="option2"
                                            defaultChecked={!edoc}
                                            onChange={handleGoGreen}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="goGreenNo"
                                        >
                                            No thanks.
                                        </label>
                                    </div>
                                </div>
                                
                                <div className="text-center mb-3">
                                    <p>By electing to receive Electronic Mail Delivery, you agree to our <a href={`${corpoSiteUrl}/privacy-policy/#terms`} target="_blank" rel="noreferrer" className="anchor-style">terms & conditions</a> and <a href={`${corpoSiteUrl}/privacy-policy/#privacy`} target="_blank" rel="noreferrer" className="anchor-style">privacy policy</a></p>
                                </div>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
            </Row>
        
        </React.Fragment>
    )
}

export default CardGoGreen
