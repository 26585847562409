import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

export const IdleTimeOutModal = ({showModal, handleStillHere, handleLogout, remainingTime}) => {

    return (
        <Modal
        isOpen={showModal}
        centered
        zIndex={'9999999999999999999999999999'}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">You Have Been Idle!</h5>
          </div>
          <ModalBody>
            <p>You Will Get Timed Out. You want to stay?</p>
            <p>Logging out in {remainingTime} seconds</p>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary"
              data-bs-target="#secondmodal"
              onClick={handleLogout}
            >
              Logout</button>
              <button className="btn btn-primary"
              data-bs-target="#secondmodal"
              onClick={handleStillHere}
            >
              Stay</button>
          </ModalFooter>
        </Modal>
    )
}