import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  TOGGLE_LOADING,
  RESET_GlOBALDATA,
  PASSWORD_CHANGE,
  RESET
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        globalData: action.payload
      }
      break
    case RESET_GlOBALDATA:
      state = {
        ...state,
        loading: false,
        isUserLogout: true,
        globalData: {}
      }
      break
    case TOGGLE_LOADING:
      state = {
        ...state,
        loading: !state.loading
      }
      break
    case LOGOUT_USER:
      state = { ...state, error: null };
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state , isUserLogout: true, error: null, loading: false, globalData: null};
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false, isUserLogout: true};
      break
    case PASSWORD_CHANGE:
      state = { ...state, msg: action.payload, loading: false, isUserLogout: true, };
      break
    case RESET:
      state = {...state, loading: false, error:'', msg: ''}
      break
    default:
      state = { ...state };
      break
  }
  return state
}

export default login
