import {
    SR_GOGREEN,
    SR_GOSMS,
    SR_GOGREEN_SUCCESS,
    SR_GOSMS_SUCCESS,
    SET_GLOBALDATA,
    SR_GETTERMDATA,
    SR_ACTION_SUCCESS
} from "./actionTypes";

import {GetGlobalData} from '../../../helpers/global_data_helper';

export const goGreen = (eDoc, loginName, globalData) => {
    
    return {
        type: SR_GOGREEN,
        payload: {
            eDoc: eDoc,
            loginName: loginName,
            globalData: globalData
        }
    };
};

export const goSMS = (sms, loginName, globalData) => {
    
    return {
        type: SR_GOSMS,
        payload: {
            sms: sms,
            loginName: loginName,
            globalData: globalData
        }
    };
};

export const goGreenSuccess = () => {
    return {
        type: SR_GOGREEN_SUCCESS
    };
};

export const goSMSSuccess = () => {
    return {
        type: SR_GOSMS_SUCCESS
    };
};

export const srActionSuccess = () => {
    return {
        type: SR_ACTION_SUCCESS
    };
};

export const setGlobalData = (globalData) => {
    return {
      type: SET_GLOBALDATA,
      payload: { globalData: globalData },
    }
}

export const loadPolicyTermData = (policyCode, recordPosition, globalData) => {
    
    return {
        type: SR_GETTERMDATA,
        payload: {
            policyCode: policyCode,
            recordPosition: recordPosition,
            globalData: globalData
        }
    };
};