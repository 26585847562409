import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
import tree from "../../../assets/images/tree.jpg";
import smsPic from "../../../assets/images/sms.jpg";

//i18n
import { withTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {GetEdocValue, GetSMSValue, GetPaymentsValue, GetNotificationCounter} from '../../../helpers/global_data_helper';
import {InstalApp} from '../../../helpers/ipapi_helpers'

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  
  const { globalData } = useSelector(state => ({
    globalData: state.Login.globalData
  }));

  const policies = globalData.policies;
  let edoc = GetEdocValue(policies);
  let sms = GetSMSValue(policies);
  let paymentNotifications = GetPaymentsValue(policies);
  let notifCounter = GetNotificationCounter([!edoc, !sms, window['deferredPrompt'], paymentNotifications.length > 0, true]);

  const [menu, setMenu] = useState(notifCounter > 0);

  function changeMenu(){
    setMenu(!menu)
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className={`bx bx-bell ${notifCounter > 0 ? "bx-tada" : ""}`} />
          <span className="badge bg-danger rounded-pill">{notifCounter}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              {/* <div className="col-auto">
                <a href="#" className="small">
                  {" "}
                  View All
                </a>
              </div> */}
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            <div className="notification-item">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1" style={{textAlign: 'center', color: 'red', fontWeight: 'bold'}}>
                    {props.t("*** Important ***")}
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1" style={{fontWeight: 'bold'}}>
                      {props.t(
                        "Please remember your Olympus homeowners policy does not cover flood. If you have placed your flood coverage with one of our flood carrier partners, please click the appropriate link below to report a flood claim."
                      )}
                    </p>
                    <Link className="btn btn-sm btn-link font-size-14 text-center" target="_blank" to="https://neptuneflood.com/report-a-claim/">
                        <i className="mdi mdi-open-in-new me-1"></i> <span key="t-view-more">{props.t("Neptune Flood")}</span>
                    </Link>
                    <Link className="btn btn-sm btn-link font-size-14 text-center" target="_blank" to="https://bfolympus.manageflood.com/">
                        <i className="mdi mdi-open-in-new me-1"></i> <span key="t-view-more">{props.t("National Flood (NFIP)")}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            
            {!edoc ? <Link onClick={changeMenu} to="/settings?tab=1" className="text-reset notification-item">
                <div className="d-flex bg-success bg-soft">
                  {/* <div className="avatar-xs me-3">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                      <i className="bx bx-cart" />
                    </span>
                  </div> */}
                  <img
                    src={tree}
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">
                      {props.t("Go Green - Go Paperless")}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {props.t(
                          "Lets protect our planet"
                        )}
                      </p>
                      {/* <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        {props.t("3 min ago")}{" "}
                      </p> */}
                    </div>
                  </div>
                </div>
              </Link>:<div></div>
            }
            {!sms ? <Link onClick={changeMenu} to="/settings?tab=1" className="text-reset notification-item">
                <div className="d-flex bg-primary bg-soft">
                  {/* <div className="avatar-xs me-3">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                      <i className="bx bx-cart" />
                    </span>
                  </div> */}
                  <img
                    src={smsPic}
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">
                      {props.t("SMS Text Notification")}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {props.t(
                          "Manage Text Notification"
                        )}
                      </p>
                      {/* <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        {props.t("3 min ago")}{" "}
                      </p> */}
                    </div>
                  </div>
                </div>
              </Link>:<div></div>
            }
            
            {/* <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <img
                  src={avatar3}
                  className="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">James Lemire</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("It will seem like simplified English") +
                        "."}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />
                      {props.t("1 hours ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link> */}
            {
              paymentNotifications.length > 0 && 

                <Link onClick={changeMenu} to="/account" className="text-reset notification-item">
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-danger rounded-circle font-size-16">
                        <i className="bx bx-dollar" />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">
                        {props.t("Pending payments")}
                      </h6>
                      <div className="font-size-12 text-muted">
                      
                          {
                            paymentNotifications.map((paymentNotif, index, array) => {

                              return (<p className="mb-1" key={index}>
                                {paymentNotif.policy}{" "} => {" "}{paymentNotif.paymentDue}
                              </p>)
                            })
                          }
                        
                        {/* <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />{" "}
                          {props.t("3 min ago")}
                        </p> */}
                      </div>
                    </div>
                  </div>
                </Link>
            }
            {
              window['deferredPrompt'] && 

              <Link onClick={InstalApp} to="#" className="text-reset notification-item">
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-success rounded-circle font-size-16">
                        <i className="mdi mdi-application-cog" />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">
                        {props.t("Install Application")}
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          {props.t(
                            "Get the native application"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
            }

            {/* <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <img
                  src={avatar4}
                  className="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">Salena Layfield</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t(
                        "As a skeptical Cambridge friend of mine occidental"
                      ) + "."}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />
                      {props.t("1 hours ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link> */}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" onClick={changeMenu} to="/settings?tab=2">
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("View More..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};