import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import { useSelector, useDispatch } from "react-redux";

const EmailUs = props => {

    const { globalData } = useSelector(state => ({
        globalData: state.Login.globalData
    }));

    const emailUs = props.target || globalData.userData[0].emailUs;

  return (
    <a className="anchor-style" href={`mailto:${emailUs}`} title={`Send an email to ${emailUs}`}>Email Us <i className="mdi mdi-send" /></a>
  )
}

EmailUs.propTypes = {

}

export default EmailUs
