import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  TOGGLE_LOADING,
  RESET_GlOBALDATA,
  PASSWORD_CHANGE,
  RESET
} from "./actionTypes";

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = globalData => {
  return {
    type: LOGIN_SUCCESS,
    payload: globalData
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const passwordChange = msg => {
  return {
    type: PASSWORD_CHANGE,
    payload: msg,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}

export const toggleLoading = () =>{
  return {
    type: TOGGLE_LOADING
  }
}

export const reset = () =>{
  return {
    type: RESET
  }
}