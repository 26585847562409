import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table, Form, Label, UncontrolledAlert, Alert
} from "reactstrap";
import { Link, useSearchParams, useLocation, useNavigate } from 'react-router-dom';

import classNames from "classnames";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import {appConfig} from "../../constants/layout";
import { map } from "lodash";
import { FormControl, MenuItem, InputLabel, Select, 
    FormLabel, FormControlLabel, Radio, RadioGroup, styled, useRadioGroup, OutlinedInput,
    ListItemText, Checkbox, Box, Chip, FormHelperText   
} from '@mui/material';
import { pink } from '@mui/material/colors';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { Radio, RadioGroup, Stack } from '@chakra-ui/react'
import Textarea from '@mui/joy/Textarea';
import reactTriggerChange from 'react-trigger-change';
import {executeRequest, getIPAPIURL} from '../../helpers/ipapi_helpers';
import {GetEnv, ShowNotification} from '../../helpers/global_data_helper';
import * as url from "../../helpers/url_helper";
import moment from 'moment';
import { toggleLoading } from "../../store/actions";

const buildFormObj = (questions) => {

    let obj = {}
    questions.map((question, index, array) => {
        obj[question.label.replaceAll(' ', '')] = (question.label.replaceAll(' ', '') === 'Whoisclaimingdamages') 
        ? 'The policyholder' : question.multiSelect ? [] : '';
    });

    obj['Policy'] = '';

    return obj;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const GetDefaultPayload = () => {
    return {
        "newclaim": {
            "options": {
                "priorClaims": 0,
                "checkDuplicates": 1,
                "siteUrl": "https://az-docs.oiconnect.com",
                "claimSummary": '<link rel="stylesheet" href="https://az-docs.oiconnect.com/Content/bootstrap_cosmoSIMS.css"><style>#ClaimSummaryContent{background-color:white;}#SelectedCoverages .col-sm-6{max-width:100%;}</style>'
            },
            "user": {
                "userName": "",
                "userId": 0
            },
            "claimIncident":
            {
                "policynumber": "",
                "incidentdate": "",
                "incidenttime": "",
                "incidentdescription": "",
                "incidentnote": "",
                "isSubro": 0,
                "howreported": "",
                "claimtype": 0,
                "fnolqData": "",
                "fnolqHtml": ""
            },
            "intake": {
                "whoReported": "",
                "reportedDate": "",
                "lossType": "",
                "peril": "",
                "severityLevel": "",
                "catCode": "",
                "adjuster": "",
                "adjusterId": ""
            },
            "claimParties":
            {
                "existingContacts": [],
                "additionalContacts": []
            },
            "coverages": [],
            "policy": "",
            "InsuredEmail": "",
            "InsuredContactPhone": "",
            "InsuredCellPhone": "",
            "InsuredWorkPhone": "",
            "CoinsuredEmail": "",
            "CoinsuredContactPhone": "",
            "CoinsuredCellPhone": "",
            "CoinsuredWorkPhone": "",
            "EMS": ""
        }
    }
};

let FnolData = {
    "LossDate": "",
    "CatCode": "",
    "PolicyNumber": "",
    "InsuredName": "",
    "PolicyAddress": "",
    "Zip": "",

    "PolicyCode": "",
    "HowReportedAnswer": undefined,
    "HowReported": "",
    "OverrideReportedDate": "",
    "OverrideDate": "",
    "LossType": "",
    "LossDescription": "",
    "SeverityLevel": "",
    "ClaimAssignmentQueue": "",
    "SelectedPolicyData": {},
    "Claimants": [],
    "Contacts": [],
    "Coverages1": [],
    "Coverages": []
};

let ClaimTypeSelected;
let ClaimType = ["First Party", "Third Party", "Hybrid"];
let HowReported = ["Phone", "Email/Fax", "Other"]
let formData_questions = {CovsTobeUse: '', EMS: ''}
let checkFNOLQuestions = false;

let SubmitClaim = GetDefaultPayload();

const FNOLFORM = props => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const location = useLocation();

    const [queryParameters] = useSearchParams();

    const { globalData } = useSelector(state => ({
        globalData: state.Login.globalData
    }));
    
    const claimsData = globalData.claimsData;
    let fnoldata = globalData.fnoldata && JSON.parse(globalData.fnoldata);
    let questions = JSON.parse(fnoldata.FNOL_Insured_Dynamic_Questions);
    let policies = globalData.policies;
    let userData = globalData.userData[0];

    // console.log(fnoldata)
    const CheckForCustomerWhoReported = () =>{
        
        let result = undefined;
        
        fnoldata.WhoReported.map((value, index, array) => {
            
            if(value.WhoDesc === 'Policyholder/Insured'){
                result = value;
            }
        });

        fnoldata.WhoReported = [result];
    }

    const [policyholderNode, setPolicyholderNode] = useState();
    const [formValue, setFormValue] = useState(buildFormObj(questions));
    const [quests, setQuests] = useState(questions);
    const [selectedPolicy, setSelectedPolicy] = useState('');
    const [alertMsg, setAlertMsg] = useState('');
    const [contactPhone, setcontactPhone] = useState('866.281.2242');
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {

        let locationPolicy = parseInt(queryParameters.get('p'));
    
        if(locationPolicy){

            policies.map((policy, index, array) => {
                
                if(policy.pD_ID === locationPolicy){
                    formValue['Policy'] = policy.pD_PolicyCode.split('-')[0];
                    forceUpdate();
                }
            });
            
        }
    }, [location]);

    const handleRadioChange = (e, question, option) => {

        formValue[question.label.replaceAll(' ', '')] = e.target.value;

        if(question.onchangeEvent && question.label === "Who is claiming damages"){
            
            if(e.target.value === 'The policyholder'){
                quests.map((quest, index, array) => {
                    if (quest.triggeredByOpt === e.target.value){
                        quest.visible = true;
                    }
                });
            }
            // else{
            //     setAlertMsg('To report a liability claim please call 866.281.2242' + 
            //     ' or contact your agent. Thank you.');
            //     console.log('trigger')
            //     reactTriggerChange($('#Whoisclaimingdamages_Thepolicyholder'));
            // }
        }

        forceUpdate();
    };

    const handleSelectChange = (e, question) => {
        
        const {
            target: { value },
        } = e;

        if(question.onchangeEvent && question.label === "What is damaged (all that apply)"){
            formValue[question.label.replaceAll(' ', '')] = typeof value === 'string' ? value.split(',') : value;
        }else{
            formValue[question.label.replaceAll(' ', '')] = value
        }

        if(question.is_fnolLossDescription){
            FnolData.LossDescription = value;
        }

        forceUpdate();
    }

    const handleDateChange = (e, question) => {
        
        let value = e.$d;
        formValue[question.label.replaceAll(' ', '')] = value;
            
        if(question.label.replaceAll(' ', '') === 'WhatistheDateofLoss?'){

            if(!moment(value).isAfter()){
                postFNOLGetPolTerm(value);
            }else{
                formValue[question.label.replaceAll(' ', '')] = 'out_of_range';
                //ShowNotification({type: 'error', message: 'Loss Date is out of range. In order to continue please change current value.'});
            }
            
        }
    }

    const CheckCovSelected = () => {
        let first = false;
        let third = false;
        let hybrid = false;

        FnolData.Coverages.map((el, index, array) => {
            if(el.coverageLabel !== 'Liability' && el.coverageLabel !== 'Medical'){
                first = true;
            }else{
                third = true;
            }
        });
    
        hybrid = first && third ? true : false;
    
        if(hybrid){
            ClaimTypeSelected = ClaimType[2];
        }else if(third){
            ClaimTypeSelected = ClaimType[1];
        }else{
            ClaimTypeSelected = ClaimType[0];
        }
    }

    const postFNOLGetPolTerm = (lossdate) => {

        const polTermBaseUrl = `${getIPAPIURL()}${url.POST_GETFNOLPOLTERMSDATA}`;
        const covBaseUrl = `${getIPAPIURL()}${url.POST_GETFNOLCOVDATA}`;
        const priorClaimsBaseUrl = `${getIPAPIURL()}${url.POST_GETFNOLPRIORCLAIMSDATA}`;

        FnolData.CatCode = GetCat(lossdate);
        FnolData.PolicyNumber = formValue['Policy'];
        FnolData.LossDate = new Date(lossdate);
    
        executeRequest(polTermBaseUrl, null, {
            PolCode: formValue['Policy'],
            injuryDate: lossdate,
            env: GetEnv()
        }, 'POST').then(function(res){
    
            try {
                
                FnolData.SelectedPolicyData = res && JSON.parse(res).data[0];

                if(FnolData.SelectedPolicyData.externalKey !== -1){
                    
                    SubmitClaim.newclaim.claimIncident.policynumber = FnolData.SelectedPolicyData.policyNumber;
                    SubmitClaim.newclaim.policy = FnolData.SelectedPolicyData.externalKey;

                    executeRequest(covBaseUrl, null, {
                        PD_ID: SubmitClaim.newclaim.policy,
                        env: GetEnv()
                    }, 'POST').then(function(res1){

                        FnolData.Coverages1 = res1 && JSON.parse(res1).data;

                        CheckCovSelected();

                        executeRequest(priorClaimsBaseUrl, null, {
                            PolCode: FnolData.SelectedPolicyData.policyNumber,
                            env: GetEnv()
                        }, 'POST').then(function(res3){

                            if(res3 && JSON.parse(res3).data.length > 0){
                                SubmitClaim.newclaim.options.priorClaims = 1;
                            }

                        });

                    });

                }else{

                    formValue['Policy'] = '';
                    alertMsg = 'The loss date you are trying to submit does not fall within the policy period of any of your know policies. ' + 
                                                'Please contact your agent or contact us at showContactPhone. Thank you.';

                    ShowNotification({type: 'warning', message: alertMsg});
                }
            
            } catch (error) {
                ShowNotification({type: 'error', message: error});
            }
        });
    };

    const GetCat = (lossDate) => {

        var result = []

        fnoldata.Catastrophe.map((el, index, array) => {
           
            var effDate = new Date(el.EffectiveDate);
            var expDate = new Date(el.ExpirationDate);
    
            if(lossDate >= effDate && lossDate <= expDate){
    
                if(result.length === 0){
                    result.push(el);
                }
            }
        })
    
        return result.length !== 0 ? result[0] : '';
    }

    const getAllPolicies = () => {
        let pols = [];

        policies.map((policy, index, array) => {

            if(policy.sPTRN_Description !== 'Cancel' && policy.sPTRN_Description !== 'Cancelled' && policy.sPTRN_Description !== 'Expired'){
                pols.push(policy.pD_PolicyCode.split('-')[0]);
            }
            
        });

        return pols;
    }

    const handleSubmit = (callback) => {
        // console.log(formValue);
        // console.log(FnolData);
        // console.log(SubmitClaim);

        FillPayloadIntake();
        UpdatePeril();
        UpdateQuestions1();
        UpdateConverages();
        UpdateEMS();
        FillPayloadClaimIncident();
        SaveCoveragesToMainPayload();
        SubmitChanges(callback);
    }

    const isSubmitBtnDisabled = () => {
        
        let policy = formValue['Policy'];
        let lossDate = formValue['WhatistheDateofLoss?'];
        let claimingdamages = formValue['Whoisclaimingdamages'];
        let causedthedamage = formValue['Whatcausedthedamage'];
        let describetheLoss = formValue['DescribetheLoss'];
        let whatisdamaged = formValue['Whatisdamaged(allthatapply)'];
        let isthereabreach = formValue['Isthereabreachintheroof,walls,orwindows?'];
        let isthereawaterleak = formValue['Isthereawaterleakthatyoucannotshutoff?'];
        let doyouneedaplace = formValue['Doyouneedaplacetostaytonight?'];
        
        return (policy === '' || lossDate === '' || lossDate === 'out_of_range'
        || claimingdamages === '' || causedthedamage === '' 
        || describetheLoss === '' || whatisdamaged.length === 0 
        || isthereabreach === '' || isthereawaterleak === '' || doyouneedaplace === '') ;
    }

    const FillPayloadUser = () => {
        SubmitClaim.newclaim.user.userId = 3;
        SubmitClaim.newclaim.user.userName =  "Customer Care";
    }

    const FillPayloadIntake = () =>{
        SubmitClaim.newclaim.intake.adjuster = FnolData.ClaimAssignmentQueue.AdjusterName;
        SubmitClaim.newclaim.intake.adjusterId = FnolData.ClaimAssignmentQueue.AdjusterID;
        SubmitClaim.newclaim.intake.catCode = (FnolData.CatCode !== undefined && FnolData.CatCode !== null) ? FnolData.CatCode.CatastropheID : '';
        //$SubmitClaim.newclaim.intake.lossType = parseInt($FnolData.LossType[0].replace('L', ''))
        SubmitClaim.newclaim.intake.severityLevel = FnolData.SeverityLevel.SeverityID;
        SubmitClaim.newclaim.intake.severityLevel = FnolData.SeverityLevel.SeverityID;
    }

    const GetPerilID = (text) => {
        var perilId = 0;


        fnoldata.FNOL_Perils.map((el, index, array) => {
            if(el.TXT === text){
                perilId = el.ID;
            }
        });
    
        return perilId;
    }

    const UpdatePeril = () => {

        SubmitClaim.newclaim.intake.peril = '';
    
        var questions = JSON.parse(fnoldata.FNOL_Dynamic_Questions);

        Object.keys(questions).map((key) => {

            let el = questions[key];

            if(el.is_fnolPeril){
    
                var dataField = el.label.replaceAll(' ', '');
                var elValue = formValue[dataField];
    
                if(typeof(elValue) === 'object'){
                    
                    SubmitClaim.newclaim.intake.peril = GetPerilID(elValue[0]);
                }else{
    
                    SubmitClaim.newclaim.intake.peril = GetPerilID(elValue);
                }
            }
        });
    }

    // To Finish
    const UpdateQuestions1 = () => {

        var items = formValue;
        var content = ''; 
        var jsonObj = {};  
        var txt = '';
        let count = 0;

        Object.keys(formValue).map((key) => {

            let el = formValue[key]

            if(key !== 'Policy' && !IsSpecialQuestion(key)){
    
                if(el === 'Describetheloss'){
                    txt = encodeURIComponent(EncodeStr(el));
                }else{
                    txt = el;
                }
    
                content += `<br><div class='fnolQ' id='IDQ${count+1}'>`;
                content += `<div id='IDQ${count+1}-1'>- ${key}</div>`;
                content += `<div id='IDQ${count+1}-2' style='font-weight: bolder;text-indent: 0.7em;'>${txt}</div></div>`;
    
                jsonObj[key] = txt;
            }

            count++;
        });
    
        checkFNOLQuestions = false;
        SubmitClaim.newclaim.claimIncident.fnolqHtml = btoa(content);
        SubmitClaim.newclaim.claimIncident.fnolqData = JSON.stringify(jsonObj);
        //$questionairePopUp.hide()
    }

    const IsSpecialQuestion = (dataField) =>{

        var questions = JSON.parse(fnoldata.FNOL_Dynamic_Questions);
        let isSpecial = false;

        questions.map((el, index, array) => {
            var dataField1 = el.label.replaceAll(' ', '');
    
            if(dataField1 === dataField){
                isSpecial = el.specialQuestion || false;
            }
        });
    
        return isSpecial;
    }

    const EncodeStr = (str) => {
        str = str || ''
        return str.replaceAll('\'', decodeURIComponent('%E2%80%99'))
    }

    const IsFieldVisible = (dataField) => {
        var items = formValue
        var isVisible = false;

        Object.keys(formValue).map((key) => {

            let el = formValue[key];

            if(el !== '' && dataField === key){
                isVisible = true;
            }
        });
    
        return isVisible;
    }

    const CoverageExists = (code) => {

        var exists = false;

        FnolData.Coverages.map((el, index, array) => {
            if(el.coverageCode === code){
    
                exists = true;
            }
        });
    
        return exists;
    }

    const UpdateConverages = () => {

        FnolData.Coverages = [];
        let UpdateConverages = JSON.parse(fnoldata.FNOL_Dynamic_Questions);

        UpdateConverages.map((el, key, array) => {

            let dataField = el.label.replaceAll(' ', '');
    
            if(el.cov_by_options && IsFieldVisible(dataField)){
    
                var elValue = formValue[dataField];
    
                if(elValue){

                    el.cov_by_options.map((el1, j, array1) => {

                        if(typeof(elValue) === 'object'){

                            elValue.map((el3, l, array2) => {

                                if(el1.opts.indexOf(el3) !== -1){

                                    FnolData.Coverages1.map((el2, k, array3) => {

                                        if(el2.coverageCode === el1.cov && !CoverageExists(el2.coverageCode)){
            
                                            FnolData.Coverages.push(el2);
                                        }
                                    });
                                }

                            });
                        }else{
    
                            if(el1.opts.indexOf(elValue) !== -1){

                                FnolData.Coverages1.map((el2, k, array3) => {

                                    if(el2.coverageCode === el1.cov && !CoverageExists(el2.coverageCode)){
                                        
                                        if(el.coverageCode === 'CovMedical'){
    
                                            if(CheckForClaimants()){
    
                                                FnolData.Coverages.push(el2);
                                            }
                                            
                                        }else{
    
                                            FnolData.Coverages.push(el2);
                                        }
                                        
                                    }
                                });
                            }
                        }

                    });
                }
            }
        });

        FnolData.Coverages1.map((el, index, array) => {
            if(el.coverageCode === 'CovLiability' && CheckForClaimants()){
    
                FnolData.Coverages.push(el);
            }
        });
    }

    const CheckForClaimants = () => {

        var claimant = false;

        FnolData.Claimants.map((el, index, array) => {

            if(el.contactType.toLocaleLowerCase().indexOf('claimant') !== -1){
                claimant = true;
            }
        });
    
        return claimant;
    }

    const UpdateEMS = () =>{

        var questions = JSON.parse(fnoldata.FNOL_Dynamic_Questions);
        SubmitClaim.newclaim.EMS = '';

        questions.map((el, i, array) => {

            var dataField = el.label.replaceAll(' ', '');
    
            if(el.ems_by_options && IsFieldVisible(dataField)){
    
                var elValue = formValue[dataField];
    
                if(elValue){

                    el.ems_by_options.map((el1, j, array) => {

                        if(typeof(elValue) === 'object'){

                            elValue.map((el3, l, array) => {
                                
                                if(el1.opts.indexOf(el3) !== -1){
                            
                                    if(SubmitClaim.newclaim.EMS === ''){
    
                                        SubmitClaim.newclaim.EMS += el1.ems;
                                    }else{
    
                                        SubmitClaim.newclaim.EMS += `,${el1.ems}`;
                                    }
                                }

                            });

                        }else{
    
                            if(el1.opts.indexOf(elValue) !== -1){
    
                                if(SubmitClaim.newclaim.EMS === ''){
                                    SubmitClaim.newclaim.EMS += el1.ems;
                                }else{
                                    SubmitClaim.newclaim.EMS += `,${el1.ems}`;
                                }
                            }
                        }

                    });
                }
            }
        });
    }

    const FillPayloadClaimIncident = () => {
        SubmitClaim.newclaim.claimIncident.claimtype = ClaimType.indexOf(ClaimTypeSelected) + 1;
        SubmitClaim.newclaim.claimIncident.howreported = 'Other';
        SubmitClaim.newclaim.claimIncident.incidentdescription = encodeURIComponent(EncodeStr(FnolData.LossDescription));
        SubmitClaim.newclaim.claimIncident.incidentdate = moment(FnolData.LossDate).format("YYYY-MM-DD");
    }

    const SaveCoveragesToMainPayload = () => {
        SubmitClaim.newclaim.coverages = [];

        FnolData.Coverages.map((el, index, array) => {
            var coverageObj = getCoverageObject2Save();
            coverageObj.coverage.coverageCode = el.coverageCode
            coverageObj.coverage.coverageValue = MoneyValue(el.limitAmount)
            SubmitClaim.newclaim.coverages.push(coverageObj)
        });
    }

    const getCoverageObject2Save = () => {
        return {
            coverage: {
                coverageCode: '',
                coverageValue: ''
            }   
        }
    }

    const MoneyValue = (value) => {
        var formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
      
        return formatter.format(value);
    }

    const SubmitChanges = (callback) => {

        const fnolUrl = `${getIPAPIURL()}${url.POST_CREATEFNOL}`;

        // console.log(formValue);
        // console.log(FnolData);
        // console.log(SubmitClaim);

        executeRequest(fnolUrl, null, {
            NewClaimJson: btoa(JSON.stringify(SubmitClaim)),
            env: GetEnv()
        }, 'POST').then(function(res){
    
            try {
                
                const resp = JSON.parse(res);
                const status = resp.data[0].status;
                const msg = resp.data[0].msg;
                const cdId = resp.data[0].claimId;
                const claimNumber = resp.data[0].claimNumber;
                const wfId = resp.data[0].wfId;
                const claimantId = resp.data[0].claimantId;

                if(status === 'success' && claimNumber !== ''){

                    if(SubmitClaim.newclaim.EMS !== ''){

                        CreateXactAssignment(cdId, claimNumber, wfId, claimantId, callback);

                    }else{

                        ShowNotification({type: 'info', message: `Claim ${claimNumber} created successfully`});

                        navigate("/claims");

                        if(callback){
                            callback();
                        }
                    }
                    
                }else{

                    var commonMSG = 'Error while attempt to create new claim. Please try again';

                    if(msg === ''){

                        ShowNotification({type: 'error', message: commonMSG});

                    }else{
                        
                        ShowNotification({type: 'error', msg: msg !== '' ? msg : commonMSG});
                    }

                    if(callback){
                        callback();
                    }
                }
            } catch (error) {
    
                ShowNotification({type: 'error', message: error});

                if(callback){
                    callback();
                }
            }
        }).catch(error => {

            ShowNotification({type: 'error', message: error});
    
            if(callback){
                callback();
            }
        });
    }

    const UpdateCommentsDate = (commentsDataSource) => {

        commentsDataSource.map((el, index, array) => {
            el.Date1 = moment(el.Date).format("MM/DD/YYYY h:mm A");
        });
        
        return commentsDataSource;
    }

    const uuidv4 = () => {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        });
    }

    const CreateXactAssignment = (claimId, claimNumber, wfId, claimantId, callback) =>{

        let payloadAPI =  {
            'type': 'NET', 'name': 'XACT.Export',
            'sessionId': userData.sessionId,
            'env': GetEnv() === 'Prod' ? GetEnv() : GetEnv().toUpperCase(),
            'Params': [
            {'name': 'WFID', 'value': 0, 'type': 'int'},
            {'name': 'claimID', 'value': claimId, 'type': 'int'},
            {'name': 'currentUsr', 'value': 3, 'type': 'int'},
            {'name': 'priority', 'value': 0, 'type': 'int'},
            {'name': 'assignedTo', 'value': 'usr_3', 'type': 'string'},
            {'name': 'claimant_Claimant_ID', 'value': claimantId, 'type': 'int'},
            {'name': 'category', 'value': GetXACTVendorID(), 'type': 'int'},
            {'name': 'subCategory', 'value': 0, 'type': 'int'},
            {'name': 'status', 'value': 1, 'type': 'int'},
            {'name': 'disposition', 'value': 0, 'type': 'int'},
            {'name': 'parentID', 'value': (wfId !== undefined && wfId !== null && wfId !== '') ? wfId : 0, 'type': 'int'},
            {'name': 'dependencies', 'value': '', 'type': 'string'},
            {'name': 'startDate', 'value': moment().format("MM/DD/YYYY"), 'type': 'date'},
            {'name': 'duedate', 'value': moment().format("MM/DD/YYYY"), 'type': 'date'},
            {'name': 'title', 'value': '', 'type': 'string'},
            {'name': 'notes', 'value': btoa(JSON.stringify(UpdateCommentsDate([{
                'Comment': '\'' + encodeURIComponent(EncodeStr(FnolData.LossDescription)) + '\'', 
                'CommentID': uuidv4(), 'Date': moment().format("MM/DD/YYYY"), 'User': 'System User'}]))), 'type': 'string'},
            {'name': 'estimateOfLoss', 'value': 0, 'type': 'float'},
            {'name': 'policyFormNumber', 'value': 0, 'type': 'int'},
            {'name': 'equipmentInvolved', 'value': '', 'type': 'string'},
            {'name': 'claimNumber', 'value': claimNumber, 'type': 'string'},
            {'name': 'policyFormNumberText', 'value': '', 'type': 'string'},
            {'name': 'vendor', 'value': userData.xActAssigneeID || 0, 'type': 'int'},
            {'name': 'isParent', 'value': 1, 'type': 'int'},
            {'name': 'assignmentTypes', 'value': 'IA', 'type': 'string'}
        ]}

        executeRequest(`${userData.oicConnectAPI_BaseURL}/api/Claims/CreateAssignment`, null, payloadAPI, 'POST').then(function(res){
    
            try {

                const claimsDataUrl = `${getIPAPIURL()}${url.POST_GETCLAIMSDATA}`;

                executeRequest(claimsDataUrl, null, {
                    Email: userData.email,
                    env: GetEnv()
                }, 'POST').then(function(res){
            
                    try {
                        
                        globalData.claimsData = JSON.parse(res).claimsData;

                        ShowNotification({type: 'info', message: `Claim ${claimNumber} created successfully`});

                        navigate("/claims");
                        
                        if(callback){
                            callback();
                        }
                    
                    } catch (error) {
            
                        ShowNotification({type: 'error', message: error});
        
                        if(callback){
                            callback();
                        }
                    }
                }).catch(error => {
        
                    ShowNotification({type: 'error', message: error});
            
                    if(callback){
                        callback();
                    }
                });
            
            } catch (error) {
    
                ShowNotification({type: 'error', message: error});

                if(callback){
                    callback();
                }
            }
        }).catch(error => {

            ShowNotification({type: 'error', message: error});
    
            if(callback){
                callback();
            }
        });
    }

    const GetXACTVendorID = () => {

        var result = 0;

        let vendors = GetAssignmentVendorsList();

        vendors.map((el, i, array) => {

            if (el.Vendor === "XACT Assignment") {
                result = el.VendorID;
            }

        });

        return result;
    }

    function GetAssignmentVendorsList() {
        var vendors = userData.vendors;
        return JSON.parse(vendors);
    }
    

    const GetErrorMessageSubS = (index) => {
        return alertMsg.split('showContactPhone')[index];
    }

    CheckForCustomerWhoReported();

    FillPayloadUser();

    SubmitClaim.newclaim.intake.reportedDate = moment().format("YYYY-MM-DD");
    SubmitClaim.newclaim.intake.whoReported = fnoldata.WhoReported[0].WhoID;

    return (
        <React.Fragment>
            <Row style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <Col lg="6">
                    <Card>
                        <CardBody>

                            {/* {alertMsg !== '' && 

                                <Alert color="warning">
                                    
                                    <i className="mdi mdi-alert-outline me-2"></i>
                                    
                                    {alertMsg.indexOf('showContactPhone') !== -1 && 
                                        
                                        GetErrorMessageSubS(0)
                                        
                                        (<a target="_blank" rel="noreferrer" className="msg-link" href={`tel:${contactPhone}`} title={`Call to ${contactPhone}`}>
                                            {contactPhone} <i className="bx bx-mobile-alt" aria-hidden="true"></i>
                                        </a>)
                                        
                                        //GetErrorMessageSubS(1)
                                    }
                                    
                                </Alert>
                            
                            } */}

                            <Alert color="warning">
                                <i className="mdi mdi-alert-outline me-2"></i>
                                To report a liability claim please call{" "}
                                <a target="_blank" rel="noreferrer" className="msg-link" href={`tel:${contactPhone}`} title={`Call to ${contactPhone}`}>
                                    {contactPhone} <i className="bx bx-mobile-alt" aria-hidden="true"></i>
                                </a>{" "}
                                or contact your agent. Thank you.
                            </Alert>

                            <Form id="fnol-form">
                                <Row id="policy" className="mb-4" style={{display: "initial"}}>
                                    <Col md="12">
                                        <FormControl fullWidth error={formValue['Policy'] ? formValue['Policy'].length === 0 : true}>
                                            <InputLabel id="label_policy">Policy</InputLabel>
                                            <Select
                                                id="select_policy"
                                                label="Policy"
                                                labelId="label_policy"
                                                value={formValue['Policy']}
                                                onChange={e => handleSelectChange(e, {label:"Policy"})}
                                            >
                                                {getAllPolicies().map((option, index1, array1) => {
                                                    return <MenuItem key={index1} value={option}>{option}</MenuItem>;
                                                })}

                                                {/* {question.dataSource && question.dataSource === 'GetLossTypeDataSource' && fnoldata.FNOL_Perils.map((option, index1, array1) => {
                                                    if(option.disabled){
                                                        return <MenuItem key={index1} disabled value={option.ID}>{option.TXT}</MenuItem>;
                                                    }else{
                                                        return <MenuItem key={index1} value={option.ID}>{option.TXT}</MenuItem>;
                                                    }
                                                })} */}
                                                
                                            </Select>
                                            {formValue['Policy'] ? formValue['Policy'].length === 0 : true && 
                                                <FormHelperText>Policy is required.</FormHelperText>
                                            }
                                            
                                        </FormControl>
                                    </Col>
                                </Row>
                                { quests.map((question, index, array) => {
                                    switch (question.type) {
                                        case "dropdown":
                                        case "tagbox":
                                        case "selectbox":

                                            switch (question.type) {
                                                case "dropdown":
                                                case "selectbox":
                                                    return (
                                                        <Row key={index} id={question.label.replaceAll(' ', '')} className="mb-4" style={{display: !question.visible ? "none" : "initial"}}>
                                                            <Col md="12">
                                                                <FormControl fullWidth error={formValue[question.label.replaceAll(' ', '')] ? formValue[question.label.replaceAll(' ', '')].length === 0 : true}>
                                                                    <InputLabel id={`label_${question.id}`}>{question.label}</InputLabel>
                                                                    <Select
                                                                        id={question.id}
                                                                        label={question.label}
                                                                        labelId={`label_${question.id}`}
                                                                        value={formValue[question.label.replaceAll(' ', '')]}
                                                                        onChange={e => handleSelectChange(e, question)}
                                                                        disabled={formValue['Policy'] === ''}
                                                                    >
                                                                        {question.options && question.options.map((option, index1, array1) => {
                                                                            if(option.disabled){
                                                                                return <MenuItem key={index1} disabled value={option}>{option}</MenuItem>;
                                                                            }else{
                                                                                return <MenuItem key={index1} value={option}>{option}</MenuItem>;
                                                                            }
                                                                        })}
        
                                                                        {question.dataSource && question.dataSource === 'GetLossTypeDataSource' && fnoldata.FNOL_Perils.map((option, index1, array1) => {

                                                                            if(option.TXT === 'Liability / Medical'){
                                                                                return <MenuItem key={index1} disabled value={option.TXT}>{option.TXT}</MenuItem>;
                                                                            }else if(option.disabled){
                                                                                return <MenuItem key={index1} disabled value={option.TXT}>{option.TXT}</MenuItem>;
                                                                            }else{
                                                                                return <MenuItem key={index1} value={option.TXT}>{option.TXT}</MenuItem>;
                                                                            }
                                                                        })}
                                                                        
                                                                    </Select>
                                                                    {
                                                                        formValue[question.label.replaceAll(' ', '')] ? formValue[question.label.replaceAll(' ', '')].length === 0 : true && 
                                                                        <FormHelperText>{`${question.label} is required.`}</FormHelperText>
                                                                    }
                                                                    
                                                                </FormControl>
                                                            </Col>
                                                        </Row>
                                                    );
                                                    break;
                                                case "tagbox":
                                                    return (
                                                        <Row key={index} id={question.label.replaceAll(' ', '')} className="mb-4" style={{display: "initial"}}>
                                                            <Col md="12">
                                                                <FormControl fullWidth error={formValue[question.label.replaceAll(' ', '')] ? formValue[question.label.replaceAll(' ', '')].length === 0 : true}>
                                                                    <InputLabel id={`label_${question.id}`}>{question.label}</InputLabel>
                                                                    <Select
                                                                        id={question.id}
                                                                        label={question.label}
                                                                        labelId={`label_${question.id}`}
                                                                        value={formValue[question.label.replaceAll(' ', '')]}
                                                                        multiple={question.multiSelect || false}
                                                                        input={<OutlinedInput label={question.label} />}
                                                                        renderValue={(selected) => (
                                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                            {selected.map((value) => (
                                                                                <Chip key={value} label={value} />
                                                                            ))}
                                                                            </Box>
                                                                        )}
                                                                        onChange={e => handleSelectChange(e, question)}
                                                                        MenuProps={MenuProps}
                                                                        disabled={formValue['Policy'] === ''}
                                                                    >
                                                                        {question.options && question.options.map((option, index1, array1) => {
                                                                            if(option.disabled){
                                                                                return <MenuItem key={index1} disabled value={option}>
                                                                                            <Checkbox checked={formValue[question.label.replaceAll(' ', '')].indexOf(option) > -1} />
                                                                                            <ListItemText primary={option} />
                                                                                        </MenuItem>;
                                                                            }else{
                                                                                return <MenuItem key={index1} value={option}>
                                                                                            <Checkbox checked={formValue[question.label.replaceAll(' ', '')].indexOf(option) > -1} />
                                                                                            <ListItemText primary={option} />
                                                                                        </MenuItem>;
                                                                            }
                                                                        })}
        
                                                                        {question.dataSource && question.dataSource === 'GetLossTypeDataSource' && fnoldata.FNOL_Perils.map((option, index1, array1) => {
                                                                            if(option.disabled){
                                                                                return <MenuItem key={index1} disabled value={option.ID}>
                                                                                            <Checkbox checked={formValue[question.label.replaceAll(' ', '')].indexOf(option.TXT) > -1} />
                                                                                            <ListItemText primary={option.TXT} />
                                                                                        </MenuItem>;
                                                                            }else{
                                                                                return <MenuItem key={index1} value={option.ID}>
                                                                                            <Checkbox checked={formValue[question.label.replaceAll(' ', '')].indexOf(option.TXT) > -1} />
                                                                                            <ListItemText primary={option.TXT} />
                                                                                        </MenuItem>;
                                                                            }
                                                                        })}
                                                                        
                                                                    </Select>
                                                                    {
                                                                        formValue[question.label.replaceAll(' ', '')] ? formValue[question.label.replaceAll(' ', '')].length === 0 : true && 
                                                                        <FormHelperText>{`${question.label} is required.`}</FormHelperText>
                                                                    }
                                                                    
                                                                </FormControl>
                                                            </Col>
                                                        </Row>
                                                    );
                                                    break;
                                            }

                                            break;
                                    
                                        case "datebox":
                                            return (
                                                <Row key={index} id={question.label.replaceAll(' ', '')} className="mb-4" style={{display: !question.visible ? "none" : "initial"}}>
                                                    <Col md="12">
                                                        <FormControl fullWidth error={formValue[question.label.replaceAll(' ', '')] ? (formValue[question.label.replaceAll(' ', '')] === '' || formValue[question.label.replaceAll(' ', '')] === 'out_of_range') : true}>
                                                            {/* <InputLabel id={`label_${question.id}`}>{question.label}</InputLabel> */}
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                {/* <DemoContainer components={['DatePicker']}>
                                                                    <DatePicker label="Basic date picker" fullWidth/>
                                                                </DemoContainer> */}
                                                                <DatePicker disableFuture={true}
                                                                label={question.label}
                                                                onChange={e => handleDateChange(e, question)}
                                                                disabled={formValue['Policy'] === ''}
                                                                // labelId={`label_${question.id}`}
                                                            />
                                                            </LocalizationProvider>

                                                            {
                                                                formValue[question.label.replaceAll(' ', '')] ? formValue[question.label.replaceAll(' ', '')] === '' : true && 
                                                                <FormHelperText>{`${question.label} is required.`}</FormHelperText>
                                                            }

                                                            {
                                                                formValue[question.label.replaceAll(' ', '')] && formValue[question.label.replaceAll(' ', '')] === 'out_of_range' && 
                                                                <FormHelperText>{`${question.label} is out of range.`}</FormHelperText>
                                                            }
                                                            
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            );
                                            break;

                                        case "radio":

                                            if(question.triggeredByOpt !== 'Another party'){

                                                return  (
                                                    <Row key={index} id={question.label.replaceAll(' ', '')} className="mb-4" style={{display: "initial"}}>
                                                        <Col md="12">
                                                            <FormControl error={formValue[question.label.replaceAll(' ', '')] ? formValue[question.label.replaceAll(' ', '')] === '' : true}>
                                                                <FormLabel id={`label_${question.id}`}>{question.label}</FormLabel>

                                                                {question.label === 'Do you need a place to stay tonight?' &&
                                                                    <FormLabel className="note-label" id={`label_${quests[index+1].id}`}>{quests[index+1].value}</FormLabel>
                                                                }
                                                                <div className="radio-toolbar d-block d-inline-flex">
                                                                    {question.options.map((option, index1, array1) => {
                                                                        
                                                                        if(option !== 'Another party'){
    
                                                                            if(option === 'The policyholder'){
                                                                                return (
                                                                                    <div key={index1} className="me-3">
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name={question.label.replaceAll(' ', '')}
                                                                                            id={`${question.label.replaceAll(' ', '')}_${option.replaceAll(' ', '')}`}
                                                                                            value={option}
                                                                                            onChange={e => handleRadioChange(e, question)}
                                                                                            checked
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor={`${question.label.replaceAll(' ', '')}_${option.replaceAll(' ', '')}`}
                                                                                        >
                                                                                            {option}
                                                                                        </label>
                                                                                    </div>
                                                                                )
                                                                            }
    
                                                                            return (
                                                                                <div key={index1} className="me-3">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name={question.label.replaceAll(' ', '')}
                                                                                        id={`${question.label.replaceAll(' ', '')}_${option.replaceAll(' ', '')}`}
                                                                                        value={option}
                                                                                        onChange={e => handleRadioChange(e, question)}
                                                                                        disabled={formValue['Policy'] === ''}
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor={`${question.label.replaceAll(' ', '')}_${option.replaceAll(' ', '')}`}
                                                                                    >
                                                                                        {option}
                                                                                    </label>
                                                                                </div>
                                                                            )
                                                                           
                                                                        }
                                                                        
                                                                    })}
                                                                </div>
                                                                {
                                                                    formValue[question.label.replaceAll(' ', '')] ? formValue[question.label.replaceAll(' ', '')] === '' : true && 
                                                                    <FormHelperText>{`${question.label} is required.`}</FormHelperText>
                                                                }
                                                                
                                                            </FormControl>
                                                        </Col>
                                                    </Row>
                                                )

                                            }
                                            break;
                                        case "textarea":
                                            if(question.is_fnolLossDescription){
                                                return (<Row key={index} id={question.label.replaceAll(' ', '')} className="mb-4" style={{display: !question.visible ? "none" : "initial"}}>
                                                        <Col md="12">
                                                            <FormControl fullWidth error={formValue[question.label.replaceAll(' ', '')] ? formValue[question.label.replaceAll(' ', '')] === '' : true}>
                                                                <FormLabel id={`label_${question.id}`}>{question.label}</FormLabel>
                                                                {question.label === 'Describe the Loss' &&
                                                                    <FormLabel className="note-label" id={`label_${quests[index+1].id}`}>{quests[index+1].value}</FormLabel>
                                                                }
                                                                <Textarea name="Plain" placeholder="Type in here…" variant="outlined" minRows={4}
                                                                    maxRows={100} onChange={e => handleSelectChange(e, question)}
                                                                    disabled={formValue['Policy'] === ''}
                                                                />

                                                                    {
                                                                        formValue[question.label.replaceAll(' ', '')] ? formValue[question.label.replaceAll(' ', '')] === '' : true && 
                                                                        <FormHelperText>{`${question.label} is required.`}</FormHelperText>
                                                                    }
                                                                
                                                            </FormControl>
                                                        </Col>
                                                    </Row>)
                                            }
                                            
                                            break;
                                    }
                                })
                            }
                            <Row id="policy" className="mb-4 text-center" style={{display: "initial"}}>
                                <Col md="12">
                                    <FormControl>
                                        <Button variant="contained" color="success" onClick={()=>{dispatch(toggleLoading()); handleSubmit(function(){dispatch(toggleLoading())})}} disabled={isSubmitBtnDisabled()}>
                                            <i className="fa fa-paper-plane" aria-hidden="true"></i>{" "}
                                            Submit
                                        </Button>
                                    </FormControl>
                                </Col>
                            </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

FNOLFORM.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(FNOLFORM);
