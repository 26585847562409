import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import {appConfig} from "../../constants/layout";

//Import Components
import CardAccount from "./card-account";
import CardGoGreen from "./card-gogreen";

import {GetGlobalData} from '../../helpers/global_data_helper';

import { setGlobalData } from "../../store/actions";

const UserAccount = props => {

  //meta title
  document.title = `My Account | ${appConfig.brandName}`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("My Account")}
            breadcrumbItem={props.t("My Account")}
          />

            {/* {!edoc && 
              <CardGoGreen/>
            } */}

            {/* Card Acocunt */}
            <CardAccount />

            {/* Card Gogreen */}
            
        </Container>
      </div>

    </React.Fragment>
  );
};

UserAccount.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(UserAccount);
