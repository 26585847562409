import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge, Form, InputGroup, Input, Collapse
} from "reactstrap";

import { Link } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";

import {GetGlobalData} from '../../helpers/global_data_helper';
import $ from 'jquery';
import { HashLink } from 'react-router-hash-link';
import {CollapseCard, scrollWithOffset} from '../../helpers/global_data_helper';
import {OpenDocuments, executePaymentProcess, getDecPage} from '../../helpers/ipapi_helpers';
import { toggleLoading } from "../../store/actions";
import EmailUs from '../../components/Common/EmailUs'

const CardAccount = props =>{

  const _that = this;
  const dispatch = useDispatch();
  
  const { globalData } = useSelector(state => ({
    globalData: state.Login.globalData
  }));

  let policies = globalData.policies || [];
  const sessionId = globalData.userData[0].sessionId

  const handlePayment = (policy) => {
    executePaymentProcess(globalData, policy);
  }

  const [col5, setcol5] = useState(true);

  const [settingsMenu, setSettingsMenu] = useState(false);
  const [searchMenu, setSearchMenu] = useState(false);
  const [otherMenu, setOtherMenu] = useState(false);

  return (
    <React.Fragment>

      {policies.map((policy, index, array) => {

        return <Row key={index}>
          <Col lg="12">
            <Card>
              <CardBody style={{cursor: "pointer"}} className="border-bottom" onClick={()=> {CollapseCard(policies, index, `i_${policy.pD_ID}`, function(){setcol5(true);})}}>
                <Row>
                  <Col md="4" xs="9">
                    <h5 className="mb-1">
                      <i className="bx bx-home me-2"/>
                      {policy.pC_address1}, {policy.pC_city}, {policy.pC_state} {policy.pC_zip}
                    </h5>
                    {
                      policy.sPTRN_Description === 'Cancel' || policy.sPTRN_Description === 'Cancelled' || policy.sPTRN_Description === 'Expired' ? 
                      
                      <div>
                        <i className="mdi mdi-circle text-danger align-middle me-1" />
                        Inactive
                      </div>                  
                      :
                      <div>
                        <i className="mdi mdi-circle text-success align-middle me-1" />
                        Active
                      </div>
                    }
                  </Col>
                  <Col md="8" xs="3">
                    <ul id={`ul_${policy.pD_ID}`} className="list-inline user-chat-nav text-end mb-0">
                      {/* <li id={`li_${policy.pD_ID}`} className="list-inline-item d-none d-sm-inline-block dropdown"> */}
                      <li id={`li_${policy.pD_ID}`} className="list-inline-item d-sm-inline-block dropdown">
                        <button id={`btn_${policy.pD_ID}`} className="btn nav-btn" type="button">
                          <i id={`i_${policy.pD_ID}`} className={policy.account_Collapse ? "mdi mdi-window-minimize" : "mdi mdi-window-maximize"} />
                        </button>
                      </li>
                      {/* <li className="list-inline-item d-none d-sm-inline-block">
                        <Dropdown
                          isOpen={settingsMenu}
                          toggle={() => {
                            setSettingsMenu(!settingsMenu);
                          }}
                        >
                          <DropdownToggle
                            tag="i"
                            className="btn nav-btn"
                            type="button"
                          >
                            <i className="bx bx-cog" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem href="#">View Profile</DropdownItem>
                            <DropdownItem href="#">Clear chat</DropdownItem>
                            <DropdownItem href="#">Muted</DropdownItem>
                            <DropdownItem href="#">Delete</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </li>

                      <li className="list-inline-item">
                        <Dropdown
                          isOpen={otherMenu}
                          toggle={() => {
                            setOtherMenu(!otherMenu);
                          }}
                        >
                          <DropdownToggle
                            tag="i"
                            className="btn nav-btn"
                            type="button"
                          >
                            <i className="bx bx-dots-horizontal-rounded" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem href="#">Action</DropdownItem>
                            <DropdownItem href="#">Another Action</DropdownItem>
                            <DropdownItem href="#">Something else</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </li> */}
                    </ul>
                  </Col>
                </Row>
              </CardBody>
              <Collapse isOpen={policy.account_Collapse}>
                <CardBody>
                  <Row>
                    <Col lg="8">
                      <div className="d-flex">
                        
                        <div className="flex-grow-1 align-self-center">
                          <div className="text-muted">
                            {/* <p className="mb-2">Welcome</p> */}
                            <h5 className="mb-1"><i className="bx bx-home me-2">
                            </i>{policy.pC_address1}, {policy.pC_city}, {policy.pC_state} {policy.pC_zip}</h5>
                            <h5>
                              Policy # {policy.pD_PolicyCode}
                            </h5>
                            <p className="mb-1"><strong>Status</strong>: {policy.sPTRN_Description}</p>
                            {policy.isPendingCancel && <p className="mb-1"><strong>Cancellation Date</strong>: {policy.cancel_Date}</p>}
                            <p className="mb-1"><strong>Form Type</strong>: {policy.sFRM_FormType}</p>

                            {policy.sPTRN_Description !== 'Cancel' && policy.sPTRN_Description !== 'Cancelled' && policy.sPTRN_Description !== 'Expired' && 
                                 <p className="mb-1">
                                 <EmailUs target="policyservices@oigfl.com"/> | <HashLink to={`/policies#${policy.pD_ID}`} className="anchor-style" scroll={el => scrollWithOffset(el)}>
                                   <span>Policy Detail</span>
                                   </HashLink> | <a onClick={()=>{
                                           dispatch(toggleLoading());
                                           OpenDocuments(getDecPage(policy), sessionId, function(){dispatch(toggleLoading());})
                                         }} className="anchor-style">Print Evidence <i className="dripicons-download me-1" /></a> | 
                                   <Link to="/claims" className="anchor-style">
                                       <span> View Claims</span>
                                       <i className="mdi mdi-clipboard-multiple-outline me-1" />
                                   </Link>  | 
                                   <Link to={`/claims/newclaim?p=${policy.pD_ID}`} className="anchor-style">
                                       <span> File a Claim</span>
                                       <i className="mdi mdi-clipboard-edit-outline me-1" />
                                     </Link>
                               </p>
                            }

                            {(policy.sPTRN_Description === 'Cancel' || policy.sPTRN_Description === 'Cancelled' || policy.sPTRN_Description === 'Expired') && 
                              <p className="mb-1">
                                <EmailUs target="policyservices@oigfl.com"/> | <HashLink to={`/policies#${policy.pD_ID}`} className="anchor-style" scroll={el => scrollWithOffset(el)}>
                                  <span>Policy Detail</span>
                                  </HashLink> | <a onClick={()=>{
                                          dispatch(toggleLoading());
                                          OpenDocuments(getDecPage(policy), sessionId, function(){dispatch(toggleLoading());})
                                        }} className="anchor-style">Print Evidence <i className="dripicons-download me-1" /></a> | 
                                  <Link to="/claims" className="anchor-style">
                                      <span> View Claims</span>
                                      <i className="mdi mdi-clipboard-multiple-outline me-1" />
                                  </Link>
                              </p>
                            }
                            
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg="4" className="align-self-center">
                      <div className="text-lg-center mt-4 mt-lg-0">
                        <Row>
                          <Col xs="6">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Currently Due
                              </p>
                              <h5 className="mb-0">{policy.pdata ? policy.pdata[0].currentlyDue !== '$0.00' ? policy.pdata[0].currentlyDue : 'N/A' : 'N/A'}</h5>
                              {policy.pdata && policy.pdata[0].currentlyDue && policy.pdata[0].currentlyDue !== '$0.00' &&
                                <a className="anchor-style btn btn-rounded btn-soft-secondary waves-effect waves-light" style={{fontSize: "1.2em"}} onClick={()=>handlePayment(policy)}>Pay now <i className="mdi mdi-cash-plus me-1" /></a>
                              }
                            </div>
                          </Col>
                          <Col xs="6">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Due by
                              </p>
                              <h5 className="mb-0">{policy.pdata && policy.pdata[0].nextInvoiceDue ? new Date(policy.pdata[0].nextInvoiceDue).toLocaleDateString() : 'N/A'}</h5>
                            </div>
                          </Col>
                          {/* <Col xs="4">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Clients
                              </p>
                              <h5 className="mb-0">18</h5>
                            </div>
                          </Col> */}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Collapse>
              
            </Card>
          </Col>
        </Row>

      })}
    </React.Fragment>
  )
}

export default CardAccount
