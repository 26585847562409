import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { 
    Row, 
    Col, 
    Card, 
    CardBody,
    Nav,
    NavItem,
    CardTitle,
    NavLink,
    TabPane,
    TabContent,
    CardText,
    Collapse,
    Table,
    UncontrolledAlert,
    Alert,
    Form
} from "reactstrap";

import Dropzone from "react-dropzone";
import classnames from "classnames";
import BootstrapTable from 'react-bootstrap-table-next';
import { useSelector, useDispatch } from "react-redux";
import EmailUs from '../../components/Common/EmailUs'

const CardDetail = () => {

  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(true);
  const [col5, setcol5] = useState(false);

  const toggleVertical = tab => {

      if (verticalActiveTab !== tab) {
          setverticalActiveTab(tab);
      }
  };

  const [selectedFiles, setselectedFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const claimsColumns = [{
    dataField: 'cD_ID',
    text: 'id',
    hidden: true
  }, {
    dataField: 'cD_ClaimCode',
    text: 'Claim'
  }, {
    dataField: 'pD_PolicyCode',
    text: 'Policy'
  },{
    dataField: 'dol',
    text: 'Date of Loss'
  },{
    dataField: 'cS_Status',
    text: 'Status'
  }];

  const { globalData } = useSelector(state => ({
    globalData: state.Login.globalData
  }));

  const claimsData = globalData.claimsData || [];

  return (
    <React.Fragment>
        <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Policy # {policyData.pD_PolicyCode} </CardTitle> */}
                  <p className="card-title-desc">
                      <EmailUs target="olympusclaims@oigfl.com"/> | 
                      <Link to="/policies" className="anchor-style">
                        <span> Policy Detail</span>
                      </Link> | 
                      <Link to="/claims/newclaim" className="anchor-style">
                        <span> File a Claim</span>
                        <i className="mdi mdi-clipboard-edit-outline me-1" />
                      </Link>
                      {/* | <a className="anchor-style">Upload Documents <i className="dripicons-upload" /></a> */}
                  </p>
                  <Row>
                    <Col md="2">
                      <Nav pills className="flex-column">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "mb-2": true,
                              active: verticalActiveTab === "1",
                            })}
                            onClick={() => {
                              toggleVertical("1");
                            }}
                          >
                            <i className="mdi mdi-eye-outline" /> Overview
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                    <Col md="10">
                      <TabContent
                        activeTab={verticalActiveTab}
                        className="text-muted mt-4 mt-md-0"
                      >
                        <TabPane tabId="1">
                          <div>
                            <Row>
                              <Card className="card-grey">
                                <CardBody className="border-bottom">
                                  <div className="d-flex align-items-center">
                                      <h5 className="mb-0 card-title flex-grow-1">Overview</h5>
                                  </div>
                                </CardBody>
                                <CardBody>
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      bootstrap4={true}
                                      keyField='cD_ID'
                                      data={ claimsData }
                                      columns={ claimsColumns }
                                      bordered={ false }
                                      expandRow={ {
                                        renderer: row => (
                                          (
                                            <div className="table-responsive">
                                              <div className="d-none d-lg-block">
                                                <Table className="table table-borderless table-sm m-0">
                                                  <tbody>
                                                    <tr>
                                                      <th scope="row" style={{width: "17%"}}>Claim Number:</th>
                                                      <td>{row.cD_ClaimCode}</td>
                                                      <th scope="row" style={{width: "23%"}}>Date of Loss:</th>
                                                      <td>{row.dol}</td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">Date Reported:</th>
                                                      <td>{row.dateReported}</td>
                                                      <th scope="row">Status:</th>
                                                      <td>{row.cS_Status}</td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">Paid Amount:</th>
                                                      <td>{row.paidTot}</td>
                                                      <th scope="row">Adjuster:</th>
                                                      <td>{row.adjusterName}{" "}
                                                        {row.adjusterPhone && row.adjusterPhone !== '' &&
                                                          <a target="_blank" rel="noreferrer" className="msg-link" href={`tel:${row.adjusterPhone}`} title={`Call to ${row.adjusterPhone}`}>
                                                            <i className="bx bx-mobile-alt" aria-hidden="true"></i>
                                                          </a>
                                                        }{" "}
                                                        {
                                                          row.adjusterEmail && row.adjusterEmail !== '' && 
                                                            <a className="msg-link ellipsisWrap" href={`mailto:${row.adjusterEmail}`} title={`Send an email to ${row.adjusterEmail}`}>
                                                              <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                                            </a>
                                                        }
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">Type of Loss:</th>
                                                      <td>{row.ccL_Loss}</td>
                                                    </tr>
                                                  </tbody>
                                                </Table>
                                              </div>
                                              <div className="d-lg-none">
                                                <Table className="table table-borderless table-sm m-0">
                                                  <tbody>
                                                    <tr>
                                                      <th scope="row">Claim Number:</th>
                                                      <td>{row.cD_ClaimCode}</td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">Date Reported:</th>
                                                      <td>{row.dateReported}</td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">Paid Amount:</th>
                                                      <td>{row.paidTot}</td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">Type of Loss:</th>
                                                      <td>{row.ccL_Loss}</td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">Date of Loss:</th>
                                                      <td>{row.dol}</td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">Status:</th>
                                                      <td>{row.cS_Status}</td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">Adjuster:</th>
                                                      <td>{row.adjusterName}{" "}
                                                        {row.adjusterPhone && row.adjusterPhone !== '' &&
                                                          <a target="_blank" rel="noreferrer" className="msg-link" href={`tel:${row.adjusterPhone}`} title={`Call to ${row.adjusterPhone}`}>
                                                            <i className="bx bx-mobile-alt" aria-hidden="true"></i>
                                                          </a>
                                                        }{" "}
                                                      {
                                                          row.adjusterEmail && row.adjusterEmail !== '' && 
                                                            <a className="msg-link ellipsisWrap" href={`mailto:${row.adjusterEmail}`} title={`Send an email to ${row.adjusterEmail}`}>
                                                              <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                                            </a>
                                                        }
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </Table>
                                              </div>
                                            </div>
                                          )
                                        ),
                                        showExpandColumn: true,
                                        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                                          return null;
                                        },
                                        expandColumnRenderer: ({
                                          expanded,
                                          rowKey,
                                          expandable
                                        }) => {
                                          if (expanded) {
                                            return (
                                              <i className="bx bxs-down-arrow" />
                                            );
                                          }
                                          return (
                                            <i className="bx bxs-right-arrow" />
                                          );
                                        },
                                      } }
                                      noDataIndication="Table is Empty"
                                    />
                                  </div>
                                </CardBody>
                              </Card>
                            </Row>
                          </div>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
    </React.Fragment>
  )
}

export default CardDetail
