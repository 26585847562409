import PropTypes from "prop-types";
import React, {useEffect} from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate  } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, socialLogin, passwordChange, reset } from "../../store/actions";

// import images
import profile from "assets/images/logo.png";
import logo from "assets/images/logo.svg";

//Import config
import { facebook, google } from "../../config";
import {appConfig} from "../../constants/layout";
import parse from 'html-react-parser'

const Login = props => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  try {

    useEffect(() => {

      dispatch(reset());
      
    }, []);
      
    useEffect(() => {

      if (localStorage.getItem("authUser")) {
        navigate("/account");
      }
      
    }, []);

  } catch (err) {}

  if (!localStorage.getItem("authUser")){

    //meta title
    document.title = `Login | ${appConfig.brandName}`;

    const validation = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,

      initialValues: {
        // email: "admin@themesbrand.com" || '',
        // password: "123456" || '',
        email: '',
        password: '',
      },
      validationSchema: Yup.object({
        email: Yup.string().required("Please Enter Your Email"),
        password: Yup.string().required("Please Enter Your Password"),
      }),
      onSubmit: (values) => {
        dispatch(loginUser(values, props.router.navigate));
      }
    });

    const { error, loading, passChangeMsg } = useSelector(state => ({
      error: state.Login.error,
      loading: state.Login.loading,
      passChangeMsg: state.Login.msg
    }));

    const signIn = (res, type) => {
      if (type === "google" && res) {
        const postData = {
          name: res.profileObj.name,
          email: res.profileObj.email,
          token: res.tokenObj.access_token,
          idToken: res.tokenId,
        };
        dispatch(socialLogin(postData, props.router.navigate, type));
      } else if (type === "facebook" && res) {
        const postData = {
          name: res.name,
          email: res.email,
          token: res.accessToken,
          idToken: res.tokenId,
        };
        dispatch(socialLogin(postData, props.router.navigate, type));
      }
    };

    //handleGoogleLoginResponse
    const googleResponse = response => {
      signIn(response, "google");
    };

    //handleTwitterLoginResponse
    // const twitterResponse = e => {}

    //handleFacebookLoginResponse
    const facebookResponse = response => {
      signIn(response, "facebook");
    };

    return (
      <React.Fragment>
        {/* <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div> */}
        <div className="account-pages-aux">
          <div className="account-pages login-screen-center">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    {/* <div className="bg-primary bg-soft"> */}
                    <div className="bg-primary">
                      <Row>
                        <Col className="col-5 centered-image" style={{justifyContent: 'flex-start'}}>
                          <img src={profile} alt="" className="img-fluid-ext"/>
                        </Col>
                        <Col xs={7}>
                          <div className="p-4-ext-1">
                            <h5 className="">{appConfig.companyName}</h5>
                            <h5 className="">{appConfig.brandName}</h5>
                          </div>
                        </Col>
                        
                      </Row>
                      <Row>
                        <Col>
                          <div className="p-4-ext-2">
                            <p>{appConfig.slogan}</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0 auth-main-win">
                      <div>
                        <Link to="/" className="logo-light-element">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                            <img
                                src={logo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                      <div className="p-2">
                        {
                          (!passChangeMsg || passChangeMsg === '') && 
                          <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            {error ? <Alert color="danger">{error}</Alert> : null}
    
                            <div className="mb-3">
                              {/* <Label className="form-label">Email</Label> */}
                              <Input
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email && validation.errors.email ? true : false
                                }
                                disabled={loading}
                              />
                              {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                              ) : null}
                            </div>
    
                            <div className="mb-3">
                              {/* <Label className="form-label">Password</Label> */}
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type="password"
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password && validation.errors.password ? true : false
                                }
                                disabled={loading}
                              />
                              {validation.touched.password && validation.errors.password ? (
                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                              ) : null}
                            </div>
    
                            {/* <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                                disabled={loading}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </label>
                            </div> */}
    
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-info btn-block"
                                type="submit"
                                disabled={loading}
                              >
                                Log In {loading &&
                                  <i className="bx bx-loader-circle bx-spin" />
                                } 
                              </button>
                            </div>
    
                            {/* <div className="mt-4 text-center">
                              <h5 className="font-size-14 mb-3">Sign in with</h5>
    
                              <ul className="list-inline">
                                <li className="list-inline-item">
                                  <FacebookLogin
                                    appId={facebook.APP_ID}
                                    autoLoad={false}
                                    callback={facebookResponse}
                                    render={renderProps => (
                                      <Link
                                        to="#"
                                        className="social-list-item bg-primary text-white border-primary"
                                        onClick={renderProps.onClick}
                                      >
                                        <i className="mdi mdi-facebook" />
                                      </Link>
                                    )}
                                  />
                                </li>
                                <li className="list-inline-item">
                                  <TwitterLogin
                                    loginUrl= "http://localhost:4000/api/v1/auth/twitter"
                                    
                                    onSuccess={this.twitterResponse}
                                    onFailure={this.onFailure}
                                    requestTokenUrl="http://localhost:4000/api/v1/auth/twitter/revers"
                                    showIcon={false}
                                    tag={"div"}
                                  >
                                    <a
                                      href=""
                                      className="social-list-item bg-info text-white border-info"
                                    >
                                      <i className="mdi mdi-twitter"/>
                                    </a>
                                  </TwitterLogin>
                                </li>
                                <li className="list-inline-item">
                                  <GoogleLogin
                                    clientId={google.CLIENT_ID}
                                    render={renderProps => (
                                      <Link
                                        to="#"
                                        className="social-list-item bg-danger text-white border-danger"
                                        onClick={renderProps.onClick}
                                      >
                                        <i className="mdi mdi-google" />
                                      </Link>
                                    )}
                                    onSuccess={googleResponse}
                                    onFailure={() => { }}
                                  />
                                </li>
                              </ul>
                            </div> */}

                            <div className="mt-4 text-center">
                              <Link to={loading ? '' : '/create-account'} className="text-muted" disabled={loading}>
                                <i className="mdi mdi-shield-account me-1" />
                                Create a Login
                              </Link>
                            </div>
    
                            <div className="mt-4 text-center">
                              <Link to={loading ? '' : '/request-password'} className="text-muted" disabled={loading}>
                                <i className="mdi mdi-lock me-1" />
                                Forgot your password?
                              </Link>
                            </div>
                            
                          </Form>
                        }
                        {passChangeMsg && passChangeMsg !== '' &&
                          <div>
                            <p>{parse(passChangeMsg.replace("Finish", "Go back"))}</p>
                            <div className="mt-4 text-center">
                              <Button onClick={()=>dispatch(passwordChange(''))} color="link" className="text-muted" disabled={loading}>
                                <i className="bx bx-exit me-1" />
                                Go back
                              </Button>
                            </div>
                          </div>  
                        }
                      </div>
                    </CardBody>
                  </Card>
                  <div className="text-center text-primary">
                    {/* <p>
                      Don&#39;t have an account ?{" "}
                      <Link to="/register" className="fw-medium text-primary">
                        {" "}
                        Signup now{" "}
                      </Link>{" "}
                    </p> */}
                    <p>
                      © {new Date().getFullYear()} Customer Portal. Created with{" "}
                      <i className="mdi mdi-heart text-danger" /> by Olympus Insurance
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        
      </React.Fragment>
    );
  }
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
