import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate  } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "assets/images/logo.png";
import logo from "assets/images/logo.svg";

//Import config
import { facebook, google } from "../../config";
import {appConfig} from "../../constants/layout";
// action
import { userForgetPassword } from "../../store/actions";
import * as url from "../../helpers/url_helper";
import {executeRequest, getIPAPIURL} from '../../helpers/ipapi_helpers';
import {GetEnv, ShowNotification} from '../../helpers/global_data_helper';
import { info } from "autoprefixer";
import { set } from "lodash";
import parse from 'html-react-parser'

const RequestPass = props => {

  //meta title
  document.title = `Request Password | ${appConfig.brandName}`;

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      setloadingExt(true)
      PasswordChangeRequest(values.email);
    }
  });

  const [infoMsg, setinfoMsg] = useState('');
  const [loadingExt, setloadingExt] = useState(false);

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  const { error, loading } = useSelector(state => ({
    error: state.Login.error,
    loading: state.Login.loading
  }));

  const navigate = useNavigate();

  const PasswordChangeRequest = (email, callback) => {

    const passwordChangeUrl = `${getIPAPIURL()}${url.POST_REQUESTPASSWORDCHANGE}`;

    executeRequest(passwordChangeUrl, null, {
      Email: email,
        env: GetEnv()
    }, 'POST').then(function(res){

      setinfoMsg(res);
      setloadingExt(false)
        
    }).catch(error => {

      setloadingExt(false)
      ShowNotification({type: 'error', message: error});

      if(callback){
          callback();
      }
    });
  }

  return (
      <React.Fragment>
        
        <div className="account-pages-aux">
          <div className="account-pages login-screen-center">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    {/* <div className="bg-primary bg-soft"> */}
                    <div className="bg-primary">
                      <Row>
                        <Col className="col-5 centered-image" style={{justifyContent: 'flex-start'}}>
                          <img src={profile} alt="" className="img-fluid-ext"/>
                        </Col>
                        <Col xs={7}>
                          <div className="p-4-ext-1">
                            <h5 className="">{appConfig.companyName}</h5>
                            <h5 className="">{appConfig.brandName}</h5>
                          </div>
                        </Col>
                        
                      </Row>
                      <Row>
                        <Col>
                          <div className="p-4-ext-2">
                            <p>{appConfig.slogan}</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0 auth-main-win">
                      <div>
                        <Link to="/" className="logo-light-element">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                            <img
                                src={logo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                      <div className="p-2">
                        {infoMsg === '' &&
                          <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            {error ? <Alert color="danger">{error}</Alert> : null}
    
                            <div className="mb-3">
                              {/* <Label className="form-label">Email</Label> */}
                              <Input
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email && validation.errors.email ? true : false
                                }
                                disabled={loadingExt}
                              />
                              {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-info btn-block"
                                type="submit"
                                disabled={loadingExt}
                              >
                                Request {loadingExt &&
                                  <i className="bx bx-loader-circle bx-spin" />
                                } 
                              </button>
                            </div>
                            <div className="mt-4 text-center">
                              <Link to="/login" className="text-muted" disabled={loading}>
                                <i className="bx bx-exit me-1" />
                                Go back to Login
                              </Link>
                            </div>
                          </Form>
                        }
                        {infoMsg !== '' &&
                          <div>
                            <p>{parse(infoMsg.replace('Finish', 'Go back to Login'))}</p>
                            <div className="mt-4 text-center">
                              <Link to="/login" className="text-muted" disabled={loading}>
                                <i className="bx bx-exit me-1" />
                                Go back to Login
                              </Link>
                            </div>
                          </div>  
                        }
                      </div>
                    </CardBody>
                  </Card>
                  <div className="text-center text-primary">
                    <p>
                      © {new Date().getFullYear()} Customer Portal. Created with{" "}
                      <i className="mdi mdi-heart text-danger" /> by Olympus Insurance
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        
      </React.Fragment>
    );
}

export default withRouter(RequestPass);

RequestPass.propTypes = {
  history: PropTypes.object,
};
