import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link, useSearchParams, useLocation, useNavigate  } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "assets/images/logo.png";
import logo from "assets/images/logo.svg";

//Import config
import { facebook, google } from "../../config";
import {appConfig} from "../../constants/layout";
// action
import { userForgetPassword } from "../../store/actions";
import * as url from "../../helpers/url_helper";
import {executeRequest, getIPAPIURL} from '../../helpers/ipapi_helpers';
import {GetEnv, ShowNotification} from '../../helpers/global_data_helper';
import { info } from "autoprefixer";
import { set } from "lodash";
import parse from 'html-react-parser'

const ChangePass = props => {

  //meta title
  document.title = `Change Password | ${appConfig.brandName}`;

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // email: "admin@themesbrand.com" || '',
      // password: "123456" || '',
      password: '',
      confirm_password: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
      confirm_password: Yup.string().required("Please Enter Your Password Confirmation") 
    }),
    onSubmit: (values) => {
      // resetPass()
    }
  });

  const [infoMsg, setinfoMsg] = useState('');
  const [token, settoken] = useState('');
  const [sessionId, setsessionId] = useState('');
  const [loginName, setloginName] = useState('');
  const [userId, setuserId] = useState(0);
  const [loadingExt, setloadingExt] = useState(false);
  const [form, setform] = useState({
      password: '',
      confirmPassword: '',
      match: null,
      charNumberValid: false,
      specialCharValid: false,
      singleQuoteValid: false,
      uppercaseValid: false,
      lowercaseValid: false,
      numberValid: false,
      commonPassValid: false,
      userNameValid: false,
      matchValid: false
  });

  const location = useLocation();

  const [queryParameters] = useSearchParams();

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  const { error, loading } = useSelector(state => ({
    error: state.Login.error,
    loading: state.Login.loading
  }));

  useEffect(() => {
    let token = queryParameters.get('tkn');
    CheckTokenValid(token)
  }, [location]);

  const navigate = useNavigate();

  const CheckTokenValid = (token, callback) => {

    const tokenValidURL = `${getIPAPIURL()}${url.POST_CHECKTOKENVALID}`;

    executeRequest(tokenValidURL, null, {
      Token: token,
      env: GetEnv()
    }, 'POST').then(function(res){

      setinfoMsg(res.msg);
      settoken(token);
      setloginName(res.loginName);
      setuserId(res.userID);
        
    }).catch(error => {

      // setloadingExt(false)
      ShowNotification({type: 'error', message: error});

      if(callback){
          callback();
      }
    });
  }

  const resetPass = (callback=null) => {

    const resetValidURL = `${getIPAPIURL()}${url.POST_RESETPASS}`;
    setloadingExt(true);

    executeRequest(resetValidURL, null, {
      Password: form.password,
      ConfPass: form.confirmPassword,
      Token: token,
      UserID: userId,
      env: GetEnv()
    }, 'POST').then(function(res){

      setinfoMsg(res);
      setloadingExt(false);
        
    }).catch(error => {

      setloadingExt(false);
      ShowNotification({type: 'error', message: error});

      if(callback){
          callback();
      }
    });
  }

  const checkPassValid = (callback=null) => {

    const passValidURL = `${getIPAPIURL()}${url.POST_CHECKPASSVALID}`;

    executeRequest(passValidURL, null, {
      Password: form.password,
      env: GetEnv()
    }, 'POST').then(function(res){

      form.commonPassValid = res;
      setform(form);
        
    }).catch(error => {

      // setloadingExt(false)
      ShowNotification({type: 'error', message: error});

      if(callback){
          callback();
      }
    });
  }

  const handlePasswordChange = (event) => {

    form.password = event.target.value;
    setform(form);
    
    checkUppercase();
    checkLowercase();
    checkNumber();
    checkPasswordLength();
    checkSpecialCharacters();
    checkSingleQuote();
    checkUserNameMatch();
    checkPasswordsMatch();
    checkPassValid();
  };

  const handlePasswordConfChange = (event) => {

    form.confirmPassword = event.target.value;
    setform(form);

    checkPasswordsMatch();
  };

  const checkPasswordsMatch = () =>{

    if(form.password === form.confirmPassword){
      form.matchValid = true
    }else{
      form.matchValid = false
    }

    setform(form);
  };

  const checkUserNameMatch = () => {

    if (loginName !== form.password) {

      form.userNameValid=true;

    } else {

      form.userNameValid=false;
    }

    setform(form);
  };

  const checkUppercase = () => {
    const pattern = /[A-Z]/;

    if (pattern.test(form.password)) {

      form.uppercaseValid=true;

    } else {

      form.uppercaseValid=false;
    }

    setform(form);
  };

  const checkLowercase = () => {
    const pattern = /[a-z]/;

    if (pattern.test(form.password)) {

      form.lowercaseValid=true;

    } else {

      form.lowercaseValid=false;
    }

    setform(form);
  };

  const checkNumber = () => {
    const pattern = /[0-9]/;
	  
    if (pattern.test(form.password)) {
      
      form.numberValid = true;
    } else {

      form.numberValid = false;
    }

    setform(form);
  };

  const checkPasswordLength = () => {
    
    if (form.password.length >= 12) {

      form.charNumberValid= true;
    } else {

      form.charNumberValid= false;
    }
  };

  const checkSpecialCharacters = () => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]/g;
	  
    if (pattern.test(form.password)) {

      form.specialCharValid = true;
    } else {
      form.specialCharValid = false;
    }
  };

  const checkSingleQuote = () => {
    const pattern = /^[^']*$/g;
	  
    if (pattern.test(form.password)) {

      form.singleQuoteValid = true;
    } else {
      form.singleQuoteValid = false;
    }
  };

  return (
      <React.Fragment>
        
        <div className="account-pages-aux">
          <div className="account-pages login-screen-center">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    {/* <div className="bg-primary bg-soft"> */}
                    <div className="bg-primary">
                      <Row>
                        <Col className="col-5 centered-image" style={{justifyContent: 'flex-start'}}>
                          <img src={profile} alt="" className="img-fluid-ext"/>
                        </Col>
                        <Col xs={7}>
                          <div className="p-4-ext-1">
                            <h5 className="">{appConfig.companyName}</h5>
                            <h5 className="">{appConfig.brandName}</h5>
                          </div>
                        </Col>
                        
                      </Row>
                      <Row>
                        <Col>
                          <div className="p-4-ext-2">
                            <p>{appConfig.slogan}</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0 auth-main-win">
                      <div>
                        <Link to="/" className="logo-light-element">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                            <img
                                src={logo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                      <div className="p-2">
                        {(infoMsg === 'valid') &&
                          <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            {error ? <Alert color="danger">{error}</Alert> : null}
    
                            <div className="mb-3">
                            {/* <Label className="form-label">Password</Label> */}
                            <Input
                              name="password"
                              value={form.password || ""}
                              type="password"
                              placeholder="Enter Password"
                              onChange={handlePasswordChange}
                              onBlur={validation.handleBlur}
                              // invalid={
                              //   validation.touched.password && validation.errors.password ? true : false
                              // }
                              // disabled={loading}
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            {/* <Label className="form-label">Confirm Password</Label> */}
                            <Input
                              name="confirm-password"
                              value={form.confirmPassword || ""}
                              type="password"
                              placeholder="Enter Password Confirmation"
                              onChange={handlePasswordConfChange}
                              onBlur={validation.handleBlur}
                              // invalid={
                              //   validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                              // }
                              // disabled={loading}
                            />
                            {validation.touched.confirm_password && validation.errors.confirm_password ? (
                              <FormFeedback type="invalid">{validation.errors.confirm_password}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="validation">
                            <div className="validator">
                              <i className={form.userNameValid ? "fas fa-check success validation-icon" : "fas fa-times error validation-icon"}></i>
                              <p className="validation-item">Password must not match your username.</p>
                            </div>
                            <div className="validator">
                              <i className={form.matchValid ? "fas fa-check success validation-icon" : "fas fa-times error validation-icon"}></i>
                              <p className="validation-item">Password must match Confirm Password.</p>
                            </div>
                            <div className="validator">
                              <i className={form.uppercaseValid ? "fas fa-check success validation-icon" : "fas fa-times error validation-icon"}></i>
                              <p className="validation-item">Password must contain at least 1 uppercase letter.</p>
                            </div>
                            <div className="validator">
                              <i className={form.lowercaseValid ? "fas fa-check success validation-icon" : "fas fa-times error validation-icon"}></i>
                              <p className="validation-item">Password must contain at least 1 lowercase letter.</p>
                            </div>
                            <div className="validator">
                              <i className={form.numberValid ? "fas fa-check success validation-icon" : "fas fa-times error validation-icon"}></i>
                              <p className="validation-item">Password must contain at least 1 number.</p>
                            </div>
                            <div className="validator">
                              <i className={form.charNumberValid ? "fas fa-check success validation-icon" : "fas fa-times error validation-icon"}></i>
                              <p className="validation-item">Password must contain at least 12 characters.</p>
                            </div>
                            <div className="validator">
                              <i className={form.specialCharValid ? "fas fa-check success validation-icon" : "fas fa-times error validation-icon"}></i>
                              <p className="validation-item">Password must contain at least 1 special character.</p>
                            </div>
                            <div className="validator">
                              <i className={form.singleQuoteValid ? "fas fa-check success validation-icon" : "fas fa-times error validation-icon"}></i>
                              <p className="validation-item">Password must not contain single quotes.</p>
                            </div>
                            <div className="validator">
                              <i className={form.commonPassValid ? "fas fa-check success validation-icon" : "fas fa-times error validation-icon"}></i>
                              <p className="validation-item">Commonly used passwords are not acceptable.</p>
                            </div>
                          </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-info btn-block"
                                type="submit"
                                disabled={!form.charNumberValid || 
                                  !form.specialCharValid ||
                                  !form.singleQuoteValid ||
                                  !form.uppercaseValid ||
                                  !form.lowercaseValid ||
                                  !form.numberValid ||
                                  !form.commonPassValid ||
                                  !form.userNameValid ||
                                  !form.matchValid}
                                  onClick={resetPass}
                              >
                                Reset {loadingExt &&
                                  <i className="bx bx-loader-circle bx-spin" />
                                } 
                              </button>
                            </div>
                            {/* <div className="mt-4 text-center">
                              <Link to="/login" className="text-muted" disabled={loading}>
                                <i className="bx bx-exit me-1" />
                                Go back to Login
                              </Link>
                            </div> */}
                            <div className="mt-4 text-center">
                              <Link to="/login" className="text-muted" disabled={loading}>
                                <i className="bx bx-exit me-1" />
                                Go back to Login
                              </Link>
                            </div>
                          </Form>
                        }
                        {
                          infoMsg === '' && 
                          <div>
                            <p>Loading...</p>                          </div>  
                        }
                        {infoMsg !== '' && infoMsg !== 'valid' &&
                          <div>
                            <p>{parse(infoMsg)}</p>
                            <div className="mt-4 text-center">
                              <Link to="/login" className="text-muted" disabled={loading}>
                                <i className="bx bx-exit me-1" />
                                Go back to Login
                              </Link>
                            </div>
                          </div>  
                        }
                      </div>
                    </CardBody>
                  </Card>
                  <div className="text-center text-primary">
                    <p>
                      © {new Date().getFullYear()} Customer Portal. Created with{" "}
                      <i className="mdi mdi-heart text-danger" /> by Olympus Insurance
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        
      </React.Fragment>
    );
}

export default withRouter(ChangePass);

ChangePass.propTypes = {
  history: PropTypes.object,
};
