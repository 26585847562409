import axios from "axios";
import { del, get, post, put } from "./api_helper_aux";
import * as url from "./url_helper";
import $ from 'jquery';
import { ShowNotification, GetEnv } from './global_data_helper';
import {store} from '../store/index';
import { loadPolicyTermData, toggleLoading, loginSuccess } from "../store/actions";

const postIPLogin = (data) => {

    const baseUrl = getIPAPIURL() + url.POST_LOGIN;

    return executeRequest(baseUrl, null, data, 'POST');
};

const postIPGoGreen = (data) => {

    const baseUrl = getIPAPIURL() + url.POST_GOGREEN;

    return executeRequest(baseUrl, null, data, 'POST');
};

const postIPGoSMS = (data) => {

    const baseUrl = getIPAPIURL() + url.POST_GOSMS;

    return executeRequest(baseUrl, null, data, 'POST');
};

const postLoadPolTerm = (data) => {

    const baseUrl = getIPAPIURL() + url.POST_GETPOLTERMDATA;

    return executeRequest(baseUrl, null, data, 'POST');
};

const dataSync = (data) => {

    const baseUrl = getIPAPIURL() + url.POST_DATASYNC;

    store.dispatch(toggleLoading());

    return executeRequest(baseUrl, null, data, 'POST').then((resp)=>{

        try {

            if(resp.userData){  

                ShowNotification({type: 'info', message: 'Data already up to date.'});
                store.dispatch(loginSuccess(resp));

            }else{

                ShowNotification({type: 'error', message: 'Error while sync operation taking place.'});
            }
        } catch (error) {

            ShowNotification({type: 'error', message: error});
            store.dispatch(toggleLoading());
        }

    }).catch(error => {
        ShowNotification({type: 'error', message: error});
        store.dispatch(toggleLoading());
    });
};

window.executePostPayment = function(data) {

    data['env'] = GetEnv();
    const baseUrl = getIPAPIURL() + url.POST_PAYMENT;

    store.dispatch(toggleLoading());

    executeRequest(baseUrl, null, data, 'POST').then(function(res){

        try {

            ShowNotification({type: 'info', message: "Payment its been processed."});

            dataSync({
                email: JSON.parse(localStorage.getItem('authUser')).email,
                env: data.env
            });
        
        } catch (error) {

            ShowNotification({type: 'error', message: error});
            store.dispatch(toggleLoading());
        }

    }).catch(error => {

        ShowNotification({type: 'error', message: error});
        store.dispatch(toggleLoading());
    });
    
};

const executePaymentProcess = (globalData, policy, callback) => {

    store.dispatch(toggleLoading());

    const baseUrl = getIPAPIURL() + url.POST_GETPAYMENTDATA;
    let data = {
        PD_ID: policy.pD_ID,
        LoginName: globalData.userData[0].username,
        zip: policy.pC_zip,
        address1: policy.pC_address1,
        name: `${policy.pC_firstname} ${policy.pC_lastname}`,
        policyCode: policy.pD_PolicyCode,
        env: GetEnv()
    };

    executeRequest(baseUrl, null, data, 'POST').then(function(res){

        try {

            ExecuteProcessOne(res, data, function(){store.dispatch(toggleLoading());});
        
        } catch (error) {

            ShowNotification({type: 'error', message: error});
            store.dispatch(toggleLoading());
        }

    }).catch(error => {
        ShowNotification({type: 'error', message: error});
        store.dispatch(toggleLoading());
    });
};

export const getDecPage = (policy) => {

    var decPageLink = '';
    
    policy.documents.map((doc, index, array) => {
      
      if(doc.formName === 'insured_forms_expand'){

        var forms = doc.forms ? JSON.parse(doc.forms) : [];
        
        forms.map((form, index, array) => {

          if(form.sREP_Name === 'Dec Page'){

            decPageLink = form.FormName;
          }
        });
      }
    });

    return decPageLink;
};

export const executeRequest = async (endpoint, accessToken,
    body = null, method = 'GET') => {

    try {

        const headers = new Headers();

        accessToken = 'wpLSNYlylfzbk9M0mnvbt8ifxDOIPcbhnPyhQPsX8dc=';

        if (accessToken) {

            const bearer = `Bearer ${accessToken}`;
            headers.append("Authorization", bearer);
        }

        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json, text/plain");

        let options = {
            method: method,
            headers: headers
        }

        if (body !== null) {
            options['body'] = JSON.stringify(body);
        }

        const response = await fetch(endpoint, options);
        const result = await response.json();

        if (!response.ok) {

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    
                    reject([result.message]);
                });
            });
        }

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(result.data);
            });
        });

    } catch (err) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                reject([err.message]);
            });
        });
    }
}

const postIPLogin1 = data => {

    return axios
        .post(getIPAPIURL() + url.POST_LOGIN, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299) return response.data;
            throw response.data;
            })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                case 404:
                    message = "Sorry! the page you are looking for could not be found";
                    break;
                case 500:
                    message =
                    "Sorry! something went wrong, please contact our support team";
                    break;
                case 401:
                    message = "Invalid credentials";
                    break;
                default:
                    message = err[1];
                    break;
                }
            }
            throw message;
        });
};

function getIPAPIURL(){

    let apiURl = 'https://';
  
    switch (GetEnv()) {
  
        case "TBT":
            apiURl += 'oicinsuredportalapidev.azurewebsites.net/';
            break;
        case "Prod":
            apiURl += 'oicinsuredportalapi.azurewebsites.net/';
            break;
    }
  
    return `${apiURl}api`;
}

function getSRURL(){

    let apiURl = 'https://';
  
    switch (GetEnv()) {
        case "TBT":
            apiURl += 'pm2-tbt.oiconnect.com';
            break;
        case "Prod":
            apiURl += 'pm2.oiconnect.com';
            break;
    }
  
    return `${apiURl}`;
}

function parseXMLResponse(xml) {
    var status = $(xml).find("stingray").find("status").text();
    var html = $(xml).find("stingray").find("html").text();
  
    return { status: status, html: html };
};

function SendRVMRequest(data, sessionId, action, method = 'POST') {
	
    var d = $.Deferred();
    var url = getSRURL() + `/${action}.max`;
    
    //logRequest(method, url, data);
    data += `&id=${sessionId}`;

    $.ajax(url, {
        method: method,
        data: data,
        // dataType: "jsonp",
        //contentType: 'application/x-www-form-urlencoded',
        timeout: 180000,
        success: function(result, status, xhr) 
        {
            var parsed = parseXMLResponse(result);
            var html = parsed.html;

            if (parsed.status === 'success') {
            
                d.resolve(html);

            } else {
                ShowNotification({type: 'error', message: html});
                d.reject('Data Loading Error');
            }
        },
        error: function(jqXHR, textStatus, errorThrown) 
        {
            d.reject(errorThrown);
        }
    });

    return d.promise();
}

function getBrowserName() {
    var userAgent = navigator ? navigator.userAgent.toLowerCase() : "other";
    
    if(userAgent.indexOf("edg") > -1){
        return "edge";
    }else if(userAgent.indexOf("opr") > -1){
        return "opera";
    }else if(userAgent.indexOf("chrome") > -1){
        return "chrome";
    } else if(userAgent.indexOf("safari") > -1){
        return "safari";
    } else if(userAgent.indexOf("msie") > -1 || navigator.appVersion.indexOf('Trident/') > 0){
        return "ie";
    } else if(userAgent.indexOf("firefox") > -1){
        return "firefox";
    } else {
        //return "ie";
        return userAgent;
    }
}

const OpenDocuments = (data, sessionId, callback, action='StaticPDF') => {
    
    if(getBrowserName() === 'chrome'){
        $.get("chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/viewer.html").done(function () {
            data += ';acrobatChromeExt*1';
            OpenDocumentsAfterCheck(data, sessionId, callback, action);
        }).fail(function () {
            data += ';acrobatChromeExt*0';
            OpenDocumentsAfterCheck(data, sessionId, callback, action);
        });
    }else{
        data += ';acrobatChromeExt*0';
        OpenDocumentsAfterCheck(data, sessionId, callback, action);
    }
}

function OpenDocumentsAfterCheck(data, sessionId, callback, action='StaticPDF') {

    var data1 = {
        encrypt: 1,
        val: `${sessionId}_${(new Date).toISOString()}`,
        DocSessData: data.split('DocSessData=')[1],
        sessionId: sessionId,
        env: GetEnv()
    }

    const baseUrl = getIPAPIURL() + url.POST_ENCRYPTDECRYPT;

    executeRequest(baseUrl, null, data1, 'POST').then(function(res){

        if(res !== 'Data Loading Error'){
            
            var newWin = window.open(`${getSRURL()}/${action}.max?IID=${btoa(res)}`);
            CheckDocViewer(newWin);
            
        }else{

            ShowNotification({type: 'error', message: 'There was an error while trying to open document selected. ' 
            + '<br>Please tr again or contact your system administrator.'});
        }

        if(callback){
            callback();
        }
    }).catch(error => {
        ShowNotification({type: 'error', message: error});

        if(callback){
            callback();
        }
    });
}

function OpenDocuments1AfterCheck(data, callback, action='StaticPDF') {
    
    data = `encrypt=1&val=${Communication.SessionID}_${(new Date).toISOString()}&${data}`;

    SendRVMRequest(data, 'ENCRYP_DECRYPT').then(function(res){

        if(res !== 'Data Loading Error'){

            var newWin = window.open(`../../${action}.max?IID=${btoa(res)}`);
            CheckDocViewer(newWin, callback);
            
        }else{

            ShowNotification({type: 'error', message: 'There was an error while trying to open document selected. ' 
            + '<br>Please tr again or contact your system administrator.'});
        }

    })
}

function OpenDocuments1(data, callback, action='StaticPDF') {

    if(getBrowserName() === 'chrome'){
        $.get("chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/viewer.html").done(function () {
            data += ';acrobatChromeExt*1';
            OpenDocuments1AfterCheck(data, callback, action);
        }).fail(function () {
            data += ';acrobatChromeExt*0';
            OpenDocuments1AfterCheck(data, callback, action);
        });
    }else{
        data += ';acrobatChromeExt*0';
        OpenDocuments1AfterCheck(data, callback, action);
    }
}

function ViewerLoadProcess(newWin, changePageContent, extCallBack){

    if(changePageContent){

        newWin.document.getElementsByTagName('body')[0].innerHTML = '<div class="header"><span>Reloading Page</span><br /></div>' +
            '<div class="pretty-print">Please wait until page loads fully...</div>';
    }else{
        
        if(extCallBack){
            extCallBack();
        }
    }
}

function CheckDocViewer(newWin, extCallBack){

    SetViewerOnload(newWin, function(){
        
        var targetExist = newWin.document.getElementById('webkit-xml-viewer-source-xml') !== null ? true : false;
        
        if(targetExist){

            SetViewerOnload(newWin, ViewerLoadProcess(newWin, true));
            newWin.location.reload();

        }else{

            ViewerLoadProcess(newWin, false, extCallBack);
        }
    })
}

function SetViewerOnload(newWin, callback){
    
    try{
        
        newWin.onload = function (event) {
        
            callback();
            
        };
    }catch(err){}
    
}

const InstalApp = async () => {
    
    window['deferredPrompt'].prompt();
    // Wait for the user to respond to the prompt
    const { outcome } = await deferredPrompt.userChoice;
    // Optionally, send analytics event with outcome of user choice
    // console.log(`User response to the install prompt: ${outcome}`);
    // We've used the prompt, and can't use it again, throw it away
    deferredPrompt = null;

    window['deferredPrompt'] = deferredPrompt
}

const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
}

export {
    
    postIPLogin,
    postIPGoGreen,
    postIPGoSMS,
    postLoadPolTerm,
    OpenDocuments,
    executePaymentProcess,
    getIPAPIURL,
    InstalApp,
    uuidv4,
    dataSync
};