import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useSelector, useDispatch  } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// users
import user1 from "../../../assets/images/users/R.png";
import * as url from "../../../helpers/url_helper";
import {executeRequest, getIPAPIURL} from '../../../helpers/ipapi_helpers';
import {GetEnv, ShowNotification} from '../../../helpers/global_data_helper';
import {  toggleLoading } from "../../../store/actions";
import {InstalApp} from '../../../helpers/ipapi_helpers'

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");

  const { globalData } = useSelector(state => ({
    globalData: state.Login.globalData
  }));

  const dispatch = useDispatch();

  const usertData = globalData.userData[0];

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);

  const PasswordChangeRequest = () => {

    const passwordChangeUrl = `${getIPAPIURL()}${url.POST_REQUESTPASSWORDCHANGE}`;

    dispatch(toggleLoading());

    executeRequest(passwordChangeUrl, null, {
      Email: usertData.username,
        env: GetEnv()
    }, 'POST').then(function(res){

      // setinfoMsg(res);
      dispatch(toggleLoading());
      ShowNotification({type: 'info', message: 'Message is been sent with details about password change operation.'});
      // setloadingExt(false)
        
    }).catch(error => {
      dispatch(toggleLoading());
      // setloadingExt(false)
      ShowNotification({type: 'error', message: error});

      if(callback){
          callback();
      }
    });
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <Link to="/profile" className="dropdown-item">
            <i className="mdi mdi-shield-key-outline font-size-16 align-middle me-1" />
            <span>{props.t("Profile")}</span>
          </Link> */}
          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </DropdownItem> */}
          <Link to="/settings?tab=1" className="dropdown-item">
            <i className="mdi mdi-shield-key-outline font-size-16 align-middle me-1" />
            <span>{props.t("Profile")}</span>
          </Link>
          <DropdownItem tag="a" href="#" onClick={PasswordChangeRequest}>
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Reset Password")}
          </DropdownItem>
          {window['deferredPrompt'] && 

            <DropdownItem tag="a" href="#" onClick={InstalApp}>
              <i className="mdi mdi-application-cog font-size-16 align-middle me-1" />
              {props.t("Install Application")}
            </DropdownItem>
          }
          
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
