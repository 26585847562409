import React, { useEffect, useState } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useSelector, useDispatch } from "react-redux";

function clearToast() {
    toastr.clear();
}

export const GetGlobalData = () => {
    
    return JSON.parse(localStorage.getItem('globalData'));
};

export const myDispatch = (action) => {

    const dispatch = useDispatch();

    return dispatch => {
      return new Promise((resolve, reject) => {
        dispatch(action);
        resolve()
      });
    }
}

export const ShowNotification = (payload) => {

    let title = payload.title;
    const showEasing = "swing";
    const hideEasing = "linear";
    const showMethod = "fadeIn";
    const hideMethod = "fadeOut";
    const showDuration = 700;
    const hideDuration = 1000;
    const timeOut = 5000;
    const extendedTimeOut = 1000;
    
    toastr.options = {
        positionClass: 'toast-top-right',
        timeOut: timeOut,
        extendedTimeOut: extendedTimeOut,
        closeButton: true,
        debug: false,
        progressBar: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: showEasing,
        hideEasing: hideEasing,
        showMethod: showMethod,
        hideMethod: hideMethod,
        showDuration: showDuration,
        hideDuration: hideDuration
      };

      switch (payload.type) {

        case "info":
            title = title !== "" ? title : "Info";
            toastr.info(payload.message, title);
            break;
        case "warning":
            title = title !== "" ? title : "Warning";
            toastr.warning(payload.message, title);
            break;
        case "error":
            title = title !== "" ? title : "Error";
            toastr.error(payload.message, title);
            break;
        default:
            toastr.success(payload.message, title);
            break;
      }    
};

export const CollapseCard = (policies, position, target, callback) => {
    
    policies[position].account_Collapse = !policies[position].account_Collapse;

    if(policies[position].account_Collapse){
      $(`#${target}`).removeClass('mdi-window-maximize').addClass('mdi-window-minimize');
    }else{
      $(`#${target}`).removeClass('mdi-window-minimize').addClass('mdi-window-maximize');
    }
    
    if(callback)
    {
      callback();
    }
};

export const corpoSiteUrl = 'https://olympusinsurance.com/';

export const scrollWithOffset = (el) => {  
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

export const GetEdocValue = (policies) => {
    let edoc = true;
    policies.map((policy, index, array) => {
        if(policy.sPTRN_Description !== 'Cancel' && policy.sPTRN_Description !== 'Cancelled' 
        && policy.sPTRN_Description !== 'Expired' && !policy.eDoc){
            edoc = false;
        }
    });

    return edoc;
}

export const GetSMSValue = (policies) => {
    let sms = true;
    policies.map((policy, index, array) => {
        if(policy.sPTRN_Description !== 'Cancel' && policy.sPTRN_Description !== 'Cancelled' 
        && policy.sPTRN_Description !== 'Expired' && !policy.sms){
            sms = false;
        }
    });

    return sms;
}

export const GetPaymentsValue = (policies) => {
    
    let pendingPayments = [];

    policies.map((policy, index, array) => {
        if(policy.pdata && policy.pdata[0].currentlyDue && policy.pdata[0].currentlyDue !== '$0.00'){
            pendingPayments.push({policy: policy.pD_PolicyCode, paymentDue: policy.pdata[0].currentlyDue});
        }
    });

    return pendingPayments;
}

export const GetNotificationCounter = (itemsArray) => {
    
    let counter = 0;

    itemsArray.map((item, index, array) => {
        if(item){
            counter++;
        }
    });

    return counter;
}

export const GetEnv = () => {

    let env = ''

    switch (location.hostname) {
        case 'localhost':
        case 'insuredportaldev.oiconnect.com':
        // case 'insuredportal.oiconnect.com':
            env = 'TBT';
            break;
        case 'insuredportal.oiconnect.com':
            env = 'Prod';
            break;
    }

    return env;
}

export const CloseSideBar = () => {

    var body = document.body;
    body.classList.toggle("sidebar-enable");
}

export const tToggle = () =>
{
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
}

export const ClosePaymentFrame = () => {
    let portalOne = $('#portalOneContainer');

    if(portalOne){
        portalOne.removeData('portalOne');
        portalOne.empty();
    }
    
}