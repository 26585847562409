import React from "react";
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

//Import Image
import tree from "../../assets/images/tree.jpg";
import smsPic from "../../assets/images/sms.jpg";
import { goSMS, setGlobalData } from "../../store/actions";
import { toggleLoading } from "../../store/actions";

import {GetGlobalData, ShowNotification, myDispatch} from '../../helpers/global_data_helper';
import {GetEdocValue, GetSMSValue, corpoSiteUrl} from '../../helpers/global_data_helper';

const CardSMS = props => {

    const { globalData } = useSelector(state => ({
        globalData: state.Login.globalData
    }));

    // const contactData = globalData.currentPolicy.contactData[0];
    const usertData = globalData.userData[0];
    // const policyData = globalData.currentPolicy.policyData[0];
    const dispatch = useDispatch();

    const policies = globalData.policies;
    let sms = GetSMSValue(policies);

    const handleGoSMS = (e) => {

        if(e.target.id === 'smssignMeUp'){
            dispatch(goSMS(1, usertData.username, globalData));
            // ShowNotification({type: 'info', message: 'You are signed up for paperless.'});
        }else{
            dispatch(goSMS(0, usertData.username, globalData));
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col lg="12" >
                    <Card>
                        <div className="bg-primary bg-soft"> 
                            <CardBody>
                                {/* <div className="d-flex align-start mb-3">
                                    <div className="flex-grow-1">
                                        <span className={item.type === "Full Time" ? "badge badge-soft-success" : item.type === "Freelance" ? "badge badge-soft-info" : item.type === "Part Time" ? "badge badge-soft-danger" : "badge badge-soft-warning"}>
                                            Elem1
                                        </span>
                                    </div>
                                    <button type="button" className="btn btn-light btn-sm like-btn" onClick={(e) => activeBtn(e.target)}>
                                        <i className="bx bx-heart"></i>
                                    </button>
                                </div> */}
                                <div className="text-center mb-3">
                                    <img src={smsPic} alt="" className="avatar-sm rounded-circle" />
                                    <h6 className="font-size-15 mt-3 mb-1 text-primary">SMS Text Notification <span style={{color: 'red'}}>(Coming Soon!)</span></h6>
                                    <p className="mb-0 text-primary">Message frequency varies, message and data rates may apply</p>
                                </div>
                                <div className="text-center mb-3">
                                    <h5>Manage Text Notification (SMS):</h5>
                                    <p className="mb-0 text-muted">SMS text notification is an excellent and easy way to communicate weather, policy and claim related information to our policyholders.</p>
                                    <p className="mb-0 text-muted">We will never take advantage of this form of communication and only use it when necessary.</p>
                                    {/* <p>{contactData.pC_address1}, {contactData.pC_city}, {contactData.pC_state} {contactData.pC_zip}</p>
                                    <p>&emsp; &#x2022; Policy # {policyData.pD_PolicyCode}</p> */}
                                </div>
                                <div className="d-flex mb-3 justify-content-center gap-2 text-muted">
                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="smsRadios"
                                            id="smssignMeUp"
                                            value="option1"
                                            defaultChecked={sms}
                                            onChange={handleGoSMS}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="smssignMeUp"
                                        >
                                            Sign me up!
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="smsRadios"
                                            id="smsmNo"
                                            value="option2"
                                            defaultChecked={!sms}
                                            onChange={handleGoSMS}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="smsmNo"
                                        >
                                            No thanks.
                                        </label>
                                    </div>
                                </div>
                                
                                <div className="text-center mb-3">
                                    <p>By electing to receive SMS Text Notification, you agree to our <a href={`${corpoSiteUrl}/mobile-terms-and-conditions`} target="_blank" rel="noreferrer" className="anchor-style">terms & conditions</a> and <a href={`${corpoSiteUrl}/privacy-policy/#privacy`} target="_blank" rel="noreferrer" className="anchor-style">privacy policy</a></p>
                                </div>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
            </Row>
        
        </React.Fragment>
    )
}

export default CardSMS
