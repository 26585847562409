import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import { 
    Row, 
    Col, 
    Card, 
    CardBody,
    Nav,
    NavItem,
    CardTitle,
    NavLink,
    TabPane,
    TabContent,
    CardText,
    Collapse,
    Table,
    UncontrolledAlert,
    Alert,
    Form,
    Button,Label, FormGroup, FormFeedback, Input
} from "reactstrap";

import Dropzone from "react-dropzone";
import classnames from "classnames";
import Editable from 'react-bootstrap-editable';
import { useSelector, useDispatch } from "react-redux";
import { loadPolicyTermData, toggleLoading } from "../../store/actions";
import BootstrapTable from 'react-bootstrap-table-next';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
//i18n
import { withTranslation } from "react-i18next";
import $ from 'jquery';
import ApexRadial from "../Dashboard/ApexRadial";
import {CollapseCard, scrollWithOffset} from '../../helpers/global_data_helper';
import {OpenDocuments, executePaymentProcess, getDecPage, executeRequest, getIPAPIURL} from '../../helpers/ipapi_helpers';
import EmailUs from '../../components/Common/EmailUs'

import SimpleBar from "simplebar-react";

import * as Yup from "yup";
import { useFormik } from "formik";
import * as url from "../../helpers/url_helper";
import {GetEnv, ShowNotification} from '../../helpers/global_data_helper';

const CardDetail = props => {

  const location = useLocation();

  const [queryParameters] = useSearchParams();
  const [verticalActiveTab, setverticalActiveTab] = useState('1');
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col5, setcol5] = useState(false);
  const [col6, setcol6] = useState(false);

  const dispatch = useDispatch();

  const toggleVertical = tab => {

    if (verticalActiveTab !== tab) {
        setverticalActiveTab(tab);
    }

  };

  useEffect(() => {
    let tabSelected = queryParameters.get('tab');

    if(tabSelected){
      toggleVertical(tabSelected);
    }
  }, [location]);
  
  const t_col1 = () => {
      setcol1(!col1);
  };

  const t_col2 = () => {
      setcol2(!col2);
  };

  const t_col5 = () => {
    setcol5(!col5);
  };

   const t_col6 = () => {
    setcol6(!col6);
  };

  const [selectedFiles, setselectedFiles] = useState([]);

  const rowEvents = {
    onMouseEnter: (e, row, rowIndex) => {

      if(row.formName !== 'insured_forms_expand'){
        $($('.expand-cell')[rowIndex]).addClass('disable-cursor')
          .unbind('click').click(function(e){
            e.stopPropagation();
          });
      }
    }
  };

  const paymentsColumns = [{
    dataField: 'transId',
    text: 'id',
    hidden: true
  }, {
    dataField: 'date',
    text: 'Date',
    style: {
      width: '23%',
    }
  },{
    dataField: 'amount',
    text: 'Amount'
  }];

  const docsColumns = [{
    dataField: 'pdC_ID',
    text: 'id',
    hidden: true
  }, {
    dataField: 'printDate',
    text: 'Print Date'
  }, {
    dataField: 'entryDate',
    text: 'Entry Date'
  },{
    dataField: 'docname',
    text: 'Form Name'
  },{
    dataField: 'sREP_Description',
    text: 'Description'
  },{
    dataField: 'formName',
    text: '',
    formatter: downloadFormatter,
    // style: { textAlign: 'center' }
  }];

  const docsColumnsAux = [{
    dataField: 'PDC_ID',
    text: 'id',
    hidden: true
  }, {
    dataField: 'PrintDate',
    text: 'Print Date'
  }, {
    dataField: 'EntryDate',
    text: 'Entry Date'
  },{
    dataField: 'sREP_Name',
    text: 'Form Name'
  },{
    dataField: 'sREP_Description',
    text: 'Description'
  },{
    dataField: 'FormName',
    text: '',
    formatter: downloadFormatter,
    style: { textAlign: 'center' }
  }];
  
  function downloadFormatter(cell, row, rowIndex, formatExtraData) {
    
    if(cell !== 'insured_forms_expand'){

      let link = row.formName || row.FormName;

      if(link.indexOf('DocSessData') !== -1){
        return (
          <a onClick={()=>{
            dispatch(toggleLoading());
            OpenDocuments(row.formName || row.FormName, sessionId, function(){dispatch(toggleLoading());})
          }} className="anchor-style"><i className="dripicons-download me-1" /></a>
         
        );
      }else{
        return (
          'Waiting on agent to print'
        );
      }
    }
  }

  function indication() {
    return "Table is Empty"
  }

  function getExpandoRowConf(documents){

    let indexs = [];

    for (let index = 0; index < documents.length; index++) {
     
      const doc = documents[index];

      if(doc.forms === ''){
        indexs.push(index)
      }
    }
    return indexs;
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const { globalData } = useSelector(state => ({
    globalData: state.Login.globalData
  }));

  let policies = globalData.policies || [];
  const usertData = globalData.userData[0]
  const sessionId = usertData.sessionId

  function LoadTermData(policy, position){
    dispatch(loadPolicyTermData(policy, position, globalData));
  }

  const handlePayment = (policy) => {
    executePaymentProcess(globalData, policy);
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      policyNumber: '',
      firstname: '',
      lastname: '',
      zip: '',
    },
    validationSchema: Yup.object({
      policyNumber: Yup.string().required("Please Enter Your Policy Number"),
      firstname: Yup.string().required("Please Enter Your Firt Name"),
      lastname: Yup.string().required("Please Enter Your Last Name"),
      zip: Yup.string().matches(
        /^[0-9]{5}(?:-[0-9]{4})?$/,
        "The Zip Code must have a correct USA format"
      ).required("Please Enter Your Zip Code"),
    }),
    onSubmit: (values) => {

      const addPolicyUrl = `${getIPAPIURL()}${url.POST_ADDPOLICY}`;

      dispatch(toggleLoading());

      executeRequest(addPolicyUrl, null, {
          LoginName: usertData.username,
          Policy: values.policyNumber,
          FName: values.firstname,
          LName: values.lastname,
          Zip: values.zip,
          env: GetEnv()
      }, 'POST').then(function(res){

        dispatch(toggleLoading());
        // validation.values = this.initialValues

        if(typeof res === 'string'){
          res = JSON.parse(res)
        }
        
        if(res.data && res.data.msg && res.data.msg !== 'success'){

          ShowNotification({type: 'error', message: res.data.msg});
        }else{

          validation.resetForm();
          globalData.policies = res.policies;
          ShowNotification({type: 'info', message: 'New policy added.'});
          setcol5(true)
        }
          
      }).catch(error => {
        dispatch(toggleLoading());
        ShowNotification({type: 'error', message: error});

      });
    }
  });

  return (
    <React.Fragment>
      <Row id="newPolicy">
        <Col lg={12}>
          <Card>
            <CardBody style={{cursor: "pointer"}} onClick={t_col6} className="border-bottom">
            <Row>
              <Col md="4" xs="9">
                <h5 className="mb-1">
                  Add New Policy
                </h5>
              </Col>
              <Col md="8" xs="3">
                <ul id={`ul_newPolicy`} className="list-inline user-chat-nav text-end mb-0">
                  {/* <li id={`li_${policy.pD_ID}`} className="list-inline-item d-none d-sm-inline-block dropdown"> */}
                  <li id={`li_newPolicy`} className="list-inline-item d-sm-inline-block dropdown">
                    <button id={`btn_newPolicy`} className="btn nav-btn" type="button">
                      <i id={`i_newPolicy`} className={col6 ? "mdi mdi-window-minimize" : "mdi mdi-window-maximize"} />
                    </button>
                  </li>
                </ul>
              </Col>
            </Row>
            </CardBody>
            <Collapse isOpen={col6}>
              <CardBody>
                <Form className="needs-validation"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        {/* <Label htmlFor="validationCustom04">Policy Number</Label> */}
                        <Input
                          name="policyNumber"
                          placeholder="Policy Number"
                          type="text"
                          className="form-control"
                          id="validationCustom04"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.policyNumber || ""}
                          invalid={
                            validation.touched.policyNumber && validation.errors.policyNumber ? true : false
                          }
                        />
                        {validation.touched.policyNumber && validation.errors.policyNumber ? (
                          <FormFeedback type="invalid">{validation.errors.policyNumber}</FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    
                    <Col md="6">
                      <FormGroup className="mb-3">
                        {/* <Label htmlFor="validationCustom05">Zip</Label> */}
                        <Input
                          name="zip"
                          placeholder="Property Address Zip Code"
                          type="text"
                          className="form-control"
                          id="validationCustom05"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.zip || ""}
                          invalid={
                            validation.touched.zip && validation.errors.zip ? true : false
                          }
                        />
                        {validation.touched.zip && validation.errors.zip ? (
                          <FormFeedback type="invalid">{validation.errors.zip}</FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        {/* <Label htmlFor="validationCustom01">Policyholder's First Name</Label> */}
                        <Input
                          name="firstname"
                          placeholder="Policyholder's First Name"
                          type="text"
                          className="form-control"
                          id="validationCustom01"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstname || ""}
                          invalid={
                            validation.touched.firstname && validation.errors.firstname ? true : false
                          }
                        />
                        {validation.touched.firstname && validation.errors.firstname ? (
                          <FormFeedback type="invalid">{validation.errors.firstname}</FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        {/* <Label htmlFor="validationCustom02">Policyholder's Last Name</Label> */}
                        <Input
                          name="lastname"
                          placeholder="Policyholder's Last Name"
                          type="text"
                          className="form-control"
                          id="validationCustom02"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastname || ""}
                          invalid={
                            validation.touched.lastname && validation.errors.lastname ? true : false
                          }
                        />
                        {validation.touched.lastname && validation.errors.lastname ? (
                          <FormFeedback type="invalid">{validation.errors.lastname}</FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Button color="primary" type="submit">
                    <i className="bx bx-plus font-size-16 align-middle me-1" />
                    Add
                  </Button>
                </Form>
              </CardBody>
            </Collapse>
          </Card>
        </Col>
      </Row>
      {policies && policies.map(function(policy, i){
          return <Row id={policy.pD_ID} key={i}>
              <Col lg={12}>
                <Card>
                  <CardBody style={{cursor: "pointer"}} onClick={(event)=> {CollapseCard(policies, i, `i_${policy.pD_ID}`, function(){setcol5(true);})}} className="border-bottom">
                  <Row>
                    <Col md="4" xs="9">
                      <h5 className="mb-1">
                        Policy # {policy.pD_PolicyCode.split('-')[0]}
                      </h5>
                      {
                        policy.sPTRN_Description === 'Cancel' || policy.sPTRN_Description === 'Cancelled' || policy.sPTRN_Description === 'Expired' ? 
                        
                        <div>
                          <i className="mdi mdi-circle text-danger align-middle me-1" />
                          Inactive
                        </div>                  
                        :
                        <div>
                          <i className="mdi mdi-circle text-success align-middle me-1" />
                          Active
                        </div>
                      }
                    </Col>
                    <Col md="8" xs="3">
                      <ul id={`ul_${policy.pD_ID}`} className="list-inline user-chat-nav text-end mb-0">
                        {/* <li id={`li_${policy.pD_ID}`} className="list-inline-item d-none d-sm-inline-block dropdown"> */}
                        <li id={`li_${policy.pD_ID}`} className="list-inline-item d-sm-inline-block dropdown">
                          <button id={`btn_${policy.pD_ID}`} className="btn nav-btn" type="button">
                            <i id={`i_${policy.pD_ID}`} className={policy.account_Collapse ? "mdi mdi-window-minimize" : "mdi mdi-window-maximize"} />
                          </button>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  </CardBody>
                  <Collapse isOpen={policy.account_Collapse}>
                    <CardBody>
                      <Row>
                        <CardTitle className="h4 policy-editable">
                          Term # &nbsp;  <Editable
                            ajax={null}
                            alwaysEditing={false}
                            className="policy-select anchor-dotted"
                            //disabled={this.props.isDeploymentLoading}
                            editText=""
                            id={policy.pD_ID}
                            initialValue={policy.pD_PolicyCode}
                            isValueClickable
                            mode="inline"
                            onSubmit={(polCode) =>
                              LoadTermData(polCode, i)}
                            onValidated={null}
                            options={policy.terms.split(',')}
                            placement="top"
                            renderCancelElement={null}
                            renderConfirmElement={null}
                            showText
                            type="select"
                            validate={null}
                        />
                        </CardTitle>
                        {policy.sPTRN_Description !== 'Cancel' && policy.sPTRN_Description !== 'Cancelled' && policy.sPTRN_Description !== 'Expired' && 
                          <p className="card-title-desc">
                            <EmailUs target="policyservices@oigfl.com"/> | <a onClick={()=>{
                                        dispatch(toggleLoading());
                                        OpenDocuments(getDecPage(policy), sessionId, function(){dispatch(toggleLoading());})
                                      }} className="anchor-style">Print Evidence <i className="dripicons-download me-1" /></a> | 
                            <Link to="/claims" className="anchor-style">
                              <span> View Claims</span>
                              <i className="mdi mdi-clipboard-multiple-outline me-1" />
                            </Link>  | 
                            <Link to={`/claims/newclaim?p=${policy.pD_ID}`} className="anchor-style">
                              <span> File a Claim</span>
                              <i className="mdi mdi-clipboard-edit-outline me-1" />
                            </Link>
                            {/* | <a className="anchor-style">Upload Documents <i className="dripicons-upload" /></a> */}
                          </p>
                        }

                        {(policy.sPTRN_Description === 'Cancel' || policy.sPTRN_Description === 'Cancelled' || policy.sPTRN_Description === 'Expired') && 

                          <p className="card-title-desc">
                            <EmailUs target="policyservices@oigfl.com"/> | <a onClick={()=>{
                                        dispatch(toggleLoading());
                                        OpenDocuments(getDecPage(policy), sessionId, function(){dispatch(toggleLoading());})
                                      }} className="anchor-style">Print Evidence <i className="dripicons-download me-1" /></a> | 
                            <Link to="/claims" className="anchor-style">
                              <span> View Claims</span>
                              <i className="mdi mdi-clipboard-multiple-outline me-1" />
                            </Link>
                          </p>
                        }
                        
                      </Row>
                      <Row>
                        <Col md="2">
                          <Nav pills className="flex-column">
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  "mb-2": true,
                                  active: verticalActiveTab === "1",
                                })}
                                onClick={() => {
                                  toggleVertical("1");
                                }}
                              >
                                <i className="mdi mdi-umbrella-outline" /> Coverage
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  "mb-2": true,
                                  active: verticalActiveTab === "2",
                                })}
                                onClick={() => {
                                  toggleVertical("2");
                                }}
                              >
                                <i className="mdi mdi-eye-outline" /> Additional Info
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  "mb-2": true,
                                  active: verticalActiveTab === "3",
                                })}
                                onClick={() => {
                                  toggleVertical("3");
                                }}
                              >
                                <i className="mdi mdi-file-multiple-outline" /> Documents
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: verticalActiveTab === "4",
                                })}
                                onClick={() => {
                                  toggleVertical("4");
                                }}
                              >
                                <i className="bx bx-dollar" /> Payments
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: verticalActiveTab === "5",
                                })}
                                onClick={() => {
                                  toggleVertical("5");
                                }}
                              >
                                <i className="bx bx-user" /> Agent
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </Col>
                        <Col md="10">
                          <TabContent
                            activeTab={verticalActiveTab}
                            className="text-muted mt-4 mt-md-0"
                          >
                            <TabPane tabId="1">
                              <div>
                                <Row>
                                  <Card className="card-grey">
                                    <CardBody className="border-bottom">
                                      <div className="d-flex align-items-center">
                                          <h5 className="mb-0 card-title flex-grow-1">Overview</h5>
                                      </div>
                                    </CardBody>
                                    <CardBody>
                                      <div className="table-responsive">
                                        <Table className="table table-borderless table-sm m-0">
                                          <tbody>
                                            <tr>
                                              <th scope="row" style={{width: "17%"}}>Insured:</th>
                                              <td>{`${policy.pC_firstname} ${policy.pC_lastname}`}</td>
                                            </tr>
                                            <tr>
                                              <th scope="row">Property Address:</th>
                                              <td>{`${policy.pC_address1}${policy.pC_address2 && policy.pC_address2 !== '' ? ', ' + policy.pC_address2 : ''}, ${policy.pC_city}, ${policy.pC_state} ${policy.pC_zip}`}</td>
                                            </tr>
                                            <tr>
                                              <th scope="row">Annual Premium:</th>
                                              <td>{policy.totalAnnualPremium}</td>
                                            </tr>

                                            {policy.pdata && policy.pdata[0].instLeft && policy.pdata[0].instLeft !== '0' &&
                                              <tr>
                                                <th scope="row"># of installments left:</th>
                                                <td>{policy.pdata[0].instLeft}{" "}{" "}{policy.pdata && policy.pdata[0].currentlyDue && policy.pdata[0].currentlyDue !== '$0.00' && 
                                                  <a className="anchor-style btn btn-sm btn-rounded btn-soft-secondary waves-effect waves-light" style={{fontSize: "1em"}} onClick={()=>handlePayment(policy)}>Pay now <i className="mdi mdi-cash-plus me-1" /></a>}
                                                </td>
                                              </tr>
                                            }
                                            
                                          </tbody>
                                        </Table>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Row>
                                <Row>
                                  <Card className="card-grey">
                                    <CardBody className="border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 card-title flex-grow-1">Coverage</h5>
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                      <div className="table-responsive">
                                        <div className="d-none d-lg-block">
                                          <Table className="table table-borderless table-sm m-0">
                                            <tbody>
                                              <tr>
                                                <th scope="row" style={{width: "17%"}}>Form Type:</th>
                                                <td>{policy.sFRM_FormType}</td>
                                                <th scope="row" style={{width: "23%"}}>Dwelling Limit:</th>
                                                <td>{policy.dwellingLimit}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Policy Status:</th>
                                                <td>{policy.sPTRN_Description}</td>
                                                <th scope="row">AOP Deductible:</th>
                                                <td>{policy.aopDeductible}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Effective Date:</th>
                                                <td>{new Date(policy.pD_EffectiveDate).toLocaleDateString()}</td>
                                                <th scope="row">Hurricane Deductible:</th>
                                                <td>{policy.hurricaneDeductible}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Expiration Date:</th>
                                                <td>{new Date(policy.pD_ExpirationDate).toLocaleDateString()}</td>
                                                <th scope="row">Non-Hurricane Wind:</th>
                                                <td>{policy.nhwDeductible}</td>
                                              </tr>
                                              <tr>
                                                <th></th>
                                                <td></td>
                                                <th scope="row">Liability Limit:</th>
                                                <td>{policy.liabilityLimit}</td>
                                              </tr>
                                            </tbody>
                                          </Table>
                                        </div>
                                        <div className="d-lg-none">
                                          <Table className="table table-borderless table-sm m-0">
                                            <tbody>
                                              <tr>
                                                <th scope="row">Form Type:</th>
                                                <td>{policy.sFRM_FormType}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Policy Status:</th>
                                                <td>{policy.sPTRN_Description}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Effective Date:</th>
                                                <td>{new Date(policy.pD_EffectiveDate).toLocaleDateString()}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Expiration Date:</th>
                                                <td>{new Date(policy.pD_ExpirationDate).toLocaleDateString()}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Dwelling Limit:</th>
                                                <td>{policy.dwellingLimit}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">AOP Deductible:</th>
                                                <td>{policy.aopDeductible}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Hurricane Deductible:</th>
                                                <td>{policy.hurricaneDeductible}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Non-Hurricane Wind:</th>
                                                <td>{policy.nhwDeductible}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Liability Limit:</th>
                                                <td>{policy.liabilityLimit}</td>
                                              </tr>
                                            </tbody>
                                          </Table>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </Card>  
                                </Row>
                              </div>
                            </TabPane>
                            <TabPane tabId="2">
                              {
                                policy.addInsData && 
                                <Row>
                                  <Card className="card-grey">
                                    <CardBody className="border-bottom">
                                      <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">Additional Insured</h5>
                                      </div>
                                    </CardBody>
                                    <CardBody>
                                      <div className="table-responsive">
                                        <SimpleBar style={{ maxHeight: "390px" }}>
                                          <ul className="list-group">
                                            {
                                              policy.addInsData.map((data, index, array) => {
                                                return (<li key={index} className="list-group-item border-0">
                                                    <div className="d-flex">
                                                      <div className="flex-shrink-0 me-3">
                                                        <div className="avatar-xs">
                                                          <span className="avatar-title rounded-circle bg-light">
                                                            <i className="dripicons-user" height="18"></i>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="flex-grow-1">
                                                        <h5 className="font-size-14">{data.additionalInsured}</h5>
                                                        <p className="text-muted">
                                                          {data.additionalInsuredAddress}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </li>)
                                              })
                                            }
                                          </ul>
                                        </SimpleBar>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Row>
                              }
                              {
                                policy.addIntsData && 
                                <Row>
                                  <Card className="card-grey">
                                    <CardBody className="border-bottom">
                                      <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">Additional Interest</h5>
                                      </div>
                                    </CardBody>
                                    <CardBody>
                                      <div className="table-responsive">
                                        <SimpleBar style={{ maxHeight: "390px" }}>
                                          <ul className="list-group">
                                            {
                                              policy.addIntsData.map((data, index, array) => {
                                                return (<li key={index} className="list-group-item border-0">
                                                    <div className="d-flex">
                                                      <div className="flex-shrink-0 me-3">
                                                        <div className="avatar-xs">
                                                          <span className="avatar-title rounded-circle bg-light">
                                                            <i className="dripicons-user" height="18"></i>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="flex-grow-1">
                                                        <h5 className="font-size-14">{data.additionalInterest}</h5>
                                                        <p className="text-muted">
                                                          {data.additionalInterestAddress}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </li>)
                                              })
                                            }
                                          </ul>
                                        </SimpleBar>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Row>
                              }
                              {
                                policy.mortgageData && 
                                <Row>
                                  <Card className="card-grey">
                                    <CardBody className="border-bottom">
                                      <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">Lienholder</h5>
                                      </div>
                                    </CardBody>
                                    <CardBody>
                                      <div className="table-responsive">
                                        <SimpleBar style={{ maxHeight: "390px" }}>
                                          <ul className="list-group">
                                            {
                                              policy.mortgageData.map((data, index, array) => {
                                                return (<li key={index} className="list-group-item border-0">
                                                    <div className="d-flex">
                                                      <div className="flex-shrink-0 me-3">
                                                        <div className="avatar-xs">
                                                          <span className="avatar-title rounded-circle bg-light">
                                                            <i className="dripicons-user" height="18"></i>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="flex-grow-1">
                                                        <h5 className="font-size-14">{data.mortgage}</h5>
                                                        <p className="text-muted">
                                                          {data.mortgageAddress}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </li>)
                                              })
                                            }
                                          </ul>
                                        </SimpleBar>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Row>
                              }
                              {
                                !policy.addInsData && !policy.addIntsData && !policy.mortgageData &&
                                <Alert color="info" className="mb-0" role="alert">
                                  <i className="mdi mdi-alert-circle-outline me-2"></i>
                                  No additional info available.
                                </Alert>
                              }
                            </TabPane>
                            <TabPane tabId="3">
                              <div>
                                <Row>
                                  <Collapse isOpen={col2}>
                                    <Form>
                                      <Dropzone
                                        onDrop={acceptedFiles => {
                                          handleAcceptedFiles(acceptedFiles)
                                        }}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick mt-2"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="mb-3">
                                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                                              </div>
                                              <h4>Drop files here or click to upload.</h4>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      <div className="dropzone-previews mt-3" id="file-previews">
                                        {selectedFiles.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center">
                                                  <Col className="col-auto">
                                                    <img
                                                      data-dz-thumbnail=""
                                                      height="80"
                                                      className="avatar-sm rounded bg-light"
                                                      alt={f.name}
                                                      src={f.preview}
                                                    />
                                                  </Col>
                                                  <Col>
                                                    <Link
                                                      to="#"
                                                      className="text-muted font-weight-bold"
                                                    >
                                                      {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                      <strong>{f.formattedSize}</strong>
                                                    </p>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          )
                                        })}
                                      </div>
                                    </Form>

                                    <div className="text-center mt-4">
                                      <button
                                        type="button"
                                        className="btn btn-primary "
                                      >
                                        Upload
                                      </button>
                                    </div>
                                  </Collapse>
                                </Row>
                                <br></br>
                                <Row>
                                  <Card className="card-grey">
                                    <CardBody className="border-bottom">
                                      <div className="d-flex align-items-center">
                                          <h5 className="mb-0 card-title flex-grow-1">
                                            Overview {" "}
                                            {/* <Link
                                              to="#"
                                              onClick={t_col2}
                                              style={{ cursor: "pointer" }}
                                            >
                                              <i className="dripicons-upload" />
                                            </Link> */}
                                            {/* <a className="anchor-style"><i className="dripicons-upload" /></a> */}
                                          </h5>
                                      </div>
                                    </CardBody>
                                    <CardBody>
                                      <div className="table-responsive">
                                        
                                        <BootstrapTable
                                          bootstrap4={true}
                                          keyField='sREP_Name'
                                          data={ policy.documents || [] }
                                          columns={ docsColumns }
                                          bordered={ false }
                                          rowEvents={ rowEvents }
                                          expandRow={ {
                                            renderer: row => (
                                              row.forms !== '' ? (
                                                <BootstrapTable
                                                  bootstrap4={true}
                                                  keyField='sREP_Name'
                                                  data={ JSON.parse(row.forms) }
                                                  columns={ docsColumnsAux }
                                                  noDataIndication={indication}
                                                />
                                              ): null
                                            ),
                                            nonExpandable: getExpandoRowConf(policy.documents),
                                            showExpandColumn: true,
                                            expandByColumnOnly: true,
                                            onlyOneExpanding: true,
                                            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                                              // if (isAnyExpands) {
                                              //   return <i className="bx bxs-down-arrow" />;
                                              // }
                                              return null;
                                            },
                                            expandColumnRenderer: ({
                                              expanded,
                                              rowKey,
                                              expandable
                                            }) => {
                                              if(rowKey.indexOf('Forms') !== -1){
                                                if (expanded) {
                                                  return (
                                                    <i className="bx bxs-down-arrow" />
                                                  );
                                                }
                                                return (
                                                  <i className="bx bxs-right-arrow" />
                                                );
                                              }else{
                                                return null;
                                              }
                                            },
                                          } }
                                          noDataIndication={indication}
                                        />


                                        {/* <Table className="table table-striped mb-0">
                                          <thead>
                                            <tr>
                                              <th>Description</th>
                                              <th>Effective Date</th>
                                              <th>Expiration Date</th>
                                              <th></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>Document1</td>
                                              <td>01/22/2023</td>
                                              <td>01/22/2024</td>
                                              <td><a className="anchor-style"><i className="dripicons-download me-1" /></a></td>
                                            </tr>
                                            <tr>
                                            <td>Document2</td>
                                              <td>01/22/2023</td>
                                              <td>01/22/2024</td>
                                              <td><a className="anchor-style"><i className="dripicons-download me-1" /></a></td>
                                            </tr>
                                            <tr>
                                            <td>Document3</td>
                                              <td>01/22/2023</td>
                                              <td>01/22/2024</td>
                                              <td><a className="anchor-style"><i className="dripicons-download me-1" /></a></td>
                                            </tr>
                                          </tbody>
                                        </Table> */}
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Row>
                              </div>
                            </TabPane>

                            <TabPane tabId="4">
                              <div>
                                <Row>
                                  <Card className="card-grey">
                                    <CardBody className="border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 card-title flex-grow-1">Overview</h5>
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                      <div className="table-responsive">
                                        <div className="d-none d-lg-block">
                                          <Table className="table table-borderless table-sm m-0">
                                            <tbody>
                                              <tr>
                                                <th scope="row" style={{width: "17%"}}>Pay Plan:</th>
                                                <td>{policy.ppdata[0].payPlan}</td>
                                                <th scope="row">Last Payment Received:</th>
                                                <td>{policy.pdata[0].pacC_EntryDate1}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row" style={{width: "23%"}}>Payor:</th>
                                                <td>{policy.payorData[0].payorType}</td>
                                                <th scope="row">Payment Amount:</th>
                                                <td>{policy.pdata[0].pacC_Credit}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Currently Due:</th>
                                                <td>{policy.pdata[0].currentlyDue}</td>
                                                <th scope="row">Remaining Payments:</th>
                                                <td>
                                                  {policy.pdata[0].instLeft}
                                                  {" "}{" "}
                                                  {policy.pdata && policy.pdata[0].currentlyDue && policy.pdata[0].currentlyDue !== '$0.00' && 
                                                    <a className="anchor-style btn btn-sm btn-rounded btn-soft-secondary waves-effect waves-light" style={{fontSize: "1em"}} onClick={()=>handlePayment(policy)}>Pay now <i className="mdi mdi-cash-plus me-1" /></a>}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Late Fee Due:</th>
                                                <td>{policy.pdata[0].lateFeeDue}</td>
                                                <th scope="row">Payoff Amount:</th>
                                                <td>{policy.pdata[0].bal}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Total Amount Currently Due:</th>
                                                <td>{policy.pdata[0].tacd}</td>
                                                <th scope="row">Currently Overdue:</th>
                                                <td>{policy.pdata[0].overDue}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Due Date:</th>
                                                <td>{policy.pdata[0].dueDate}</td>
                                              </tr>
                                            </tbody>
                                          </Table>
                                        </div>
                                        <div className="d-lg-none">
                                          <Table className="table table-borderless table-sm m-0">
                                            <tbody>
                                              <tr>
                                                <th scope="row">Pay Plan:</th>
                                                <td>{policy.ppdata[0].payPlan}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Payor:</th>
                                                <td>{policy.payorData[0].payorType}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Currently Due:</th>
                                                <td>{policy.pdata[0].currentlyDue}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Late Fee Due:</th>
                                                <td>{policy.pdata[0].lateFeeDue}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Total Amount Currently Due:</th>
                                                <td>{policy.pdata[0].tacd}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Due Date:</th>
                                                <td>{policy.pdata[0].dueDate}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Last Payment Received:</th>
                                                <td>{policy.pdata[0].pacC_EntryDate1}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Payment Amount:</th>
                                                <td>{policy.pdata[0].pacC_Credit}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Remaining Payments:</th>
                                                <td>
                                                  {policy.pdata[0].instLeft}{" "}{" "}
                                                  {policy.pdata && policy.pdata[0].currentlyDue && policy.pdata[0].currentlyDue !== '$0.00' && 
                                                    <a className="anchor-style btn btn-sm btn-rounded btn-soft-secondary waves-effect waves-light" style={{fontSize: "1em"}} onClick={()=>handlePayment(policy)}>Pay now <i className="mdi mdi-cash-plus me-1" /></a>}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Payoff Amount:</th>
                                                <td>{policy.pdata[0].bal}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Currently Overdue:</th>
                                                <td>{policy.pdata[0].overDue}</td>
                                              </tr>
                                            </tbody>
                                          </Table>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </Card>  
                                </Row>
                                <Row>
                                  <Col lg={6}>
                                    <Card className="card-grey">
                                      <CardBody className="border-bottom">
                                          <div className="d-flex align-items-center">
                                            <h5 className="mb-0 card-title flex-grow-1">Progress</h5>
                                          </div>
                                      </CardBody>
                                      <CardBody>
                                        <Row>
                                          <Col sm="12">
                                            <p className="text-muted">Total</p>
                                            <h3>{policy.totalAnnualPremium}</h3>
                                            {/* <p className="text-muted">
                                              <span className="text-success me-2">
                                                {" "}
                                                12% <i className="mdi mdi-arrow-up"></i>{" "}
                                              </span>{" "}
                                              From previous period (TODO)
                                            </p> */}
                                          </Col>
                                          {/* <Col sm="6">
                                            <div className="mt-4 mt-sm-0">
                                              <ApexRadial dataColors='["--bs-primary"]' seriesExt={[parseInt(parseFloat(policy.pdata[0].pacC_Credit.replace('$', '').replace(',','')) * 100 / parseFloat(policy.totalAnnualPremium.replace('$', '').replace(',','')))]} labelsExt={["Completed"]}/>
                                            </div>
                                          </Col> */}
                                        </Row>
                                        {/* <p className="text-muted mb-0">
                                          “Goals are dreams with deadlines.” ― Diana Scharf
                                        </p> */}
                                      </CardBody>
                                    </Card>  
                                  </Col>
                                  <Col lg={6}>
                                    <Card className="card-grey">
                                      <CardBody className="border-bottom">
                                          <div className="d-flex align-items-center">
                                            <h5 className="mb-0 card-title flex-grow-1">Billing History</h5>
                                          </div>
                                      </CardBody>
                                      <CardBody>
                                        {/* <div className="table-responsive">
                                          
                                          <BootstrapTable
                                            bootstrap4={true}
                                            keyField='transId'
                                            data={ policy.payments }
                                            bordered={ false }
                                            columns={ paymentsColumns }
                                            noDataIndication={indication}
                                          />
                                        </div> */}
                                        <ul className="verti-timeline list-unstyled">
                                          {policy.payments && policy.payments.map((value, index, array) => {
                                            return <li className="event-list" key={index}>
                                                <div className="event-timeline-dot">
                                                  <i className="bx bx-right-arrow-circle font-size-18" />
                                                </div>
                                                <div className="flex-shrink-0 d-flex">
                                                  <div className="me-3">
                                                    <h5 className="font-size-14">
                                                      {value.date}{" "}
                                                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                                    </h5>
                                                  </div>
                                                  <div className="flex-grow-1">
                                                    <div>{value.amount}</div>
                                                  </div>
                                                </div>
                                            </li>
                                          })
                                          }
                                        </ul>
                                      </CardBody>
                                    </Card>  
                                  </Col>
                                </Row>
                              </div>
                            </TabPane>
                            <TabPane tabId="5">
                              <div>
                                <Row>
                                  <Card className="card-grey">
                                    <CardBody className="border-bottom">
                                      <div className="d-flex align-items-center">
                                          <h5 className="mb-0 card-title flex-grow-1">Overview</h5>
                                      </div>
                                    </CardBody>
                                    <CardBody>
                                      <div className="table-responsive">
                                        <div className="d-none d-lg-block">
                                          <Table className="table table-borderless table-sm m-0">
                                            <tbody>
                                              <tr>
                                                <th scope="row" style={{width: "11%"}}>Agency:</th>
                                                <td>{policy.agencyData[0].agencyName}</td>
                                                <th scope="row" style={{width: "11%"}}>Email:</th>
                                                <td>
                                                  <a className="msg-link ellipsisWrap" href={`mailto:${policy.agencyData[0].agencyEmail}`} title={`Send an email to ${policy.agencyData[0].agencyEmail}`}>
                                                    {policy.agencyData[0].agencyEmail} <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Address:</th>
                                                <td>{`${policy.agencyData[0].agencyAdd1}${policy.agencyData[0].agencyAdd2 && policy.agencyData[0].agencyAdd2 !== '' && policy.agencyData[0].agencyAdd2 !== 'N/A' ? ', ' + policy.agencyData[0].agencyAdd2 : ''}, ${policy.agencyData[0].agencyCity}, ${policy.agencyData[0].agencyState} ${policy.agencyData[0].agencyZip}`}</td>
                                                <th scope="row">Phone:</th>
                                                <td>
                                                  <a target="_blank" rel="noreferrer" className="msg-link" href={`tel:${policy.agencyData[0].agencyPhone}`} title={`Call to ${policy.agencyData[0].agencyPhone}`}>
                                                    {policy.agencyData[0].agencyPhone} <i className="bx bx-mobile-alt" aria-hidden="true"></i>
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row"></th>
                                                <td></td>
                                                <th scope="row">Fax:</th>
                                                <td>{policy.agencyData[0].agencyFax}</td>
                                              </tr>
                                            </tbody>
                                          </Table>
                                        </div>
                                        <div className="d-lg-none">
                                          <Table className="table table-borderless table-sm m-0">
                                            <tbody>
                                              <tr>
                                                <th scope="row">Agency:</th>
                                                <td>{policy.agencyData[0].agencyName}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Address:</th>
                                                <td>{`${policy.agencyData[0].agencyAdd1}${policy.agencyData[0].agencyAdd2 && policy.agencyData[0].agencyAdd2 !== '' && policy.agencyData[0].agencyAdd2 !== 'N/A' ? ', ' + policy.agencyData[0].agencyAdd2 : ''}, ${policy.agencyData[0].agencyCity}, ${policy.agencyData[0].agencyState} ${policy.agencyData[0].agencyZip}`}</td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Email:</th>
                                                <td>
                                                  <a className="msg-link ellipsisWrap" href={`mailto:${policy.agencyData[0].agencyEmail}`} title={`Send an email to ${policy.agencyData[0].agencyEmail}`}>
                                                    {policy.agencyData[0].agencyEmail} <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Phone:</th>
                                                <td>
                                                  <a target="_blank" rel="noreferrer" className="msg-link" href={`tel:${policy.agencyData[0].agencyPhone}`} title={`Call to ${policy.agencyData[0].agencyPhone}`}>
                                                    {policy.agencyData[0].agencyPhone} <i className="bx bx-mobile-alt" aria-hidden="true"></i>
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row">Fax:</th>
                                                <td>{policy.agencyData[0].agencyFax}</td>
                                              </tr>
                                            </tbody>
                                          </Table>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Row>
                              </div>
                            </TabPane>
                          </TabContent>
                        </Col>
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
            </Row>
      })}
    </React.Fragment>
  )
}

CardDetail.propTypes = {
  t: PropTypes.any,
  tab: PropTypes.string
};

export default withTranslation()(CardDetail)
