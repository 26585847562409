import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { SR_GOGREEN, SR_GOSMS, SET_GLOBALDATA, SR_GETTERMDATA, SR_ACTION_SUCCESS } from "./actionTypes";
import { apiError } from "../../auth/login/actions";
import { goGreenSuccess } from "./actions";

import { postIPGoGreen, postIPGoSMS, postLoadPolTerm} from '../../../helpers/ipapi_helpers';
import { loginSuccess } from "./../../auth/login/actions";
import { toggleLoading } from "./../../actions";
import {GetGlobalData, ShowNotification, GetEnv} from '../../../helpers/global_data_helper';


function* goGreen({payload: { pdId: pdId, eDoc: eDoc, loginName: loginName, globalData: globalData }}) {

  try {

    yield put(toggleLoading());
    let msg = 'You are signed up for paperless.';

    const response = yield call(postIPGoGreen, {
      AJAX_ACTION: 'EDoc_Click',
      IsOptIn: eDoc,
      LoginName: loginName,
      env: GetEnv()
    });

    let policies = globalData.policies;
    
    policies.map((policie, index, array) => {
      policie.eDoc = eDoc === 1;
    });

    if(eDoc !== 1){
      msg = 'You are signed off for paperless.';
    }

    yield call(ShowNotification, {type: 'info', message: msg});
    yield put(toggleLoading());

  } catch (error) {

    yield put(toggleLoading());
    yield call(ShowNotification, {type: 'error', message: error});
    yield put(apiError(error));
  }
}

function* goSMS({payload: { pdId: pdId, sms: sms, loginName: loginName, globalData: globalData }}) {

  try {

    yield put(toggleLoading());
    let msg = 'You are signed up for SMS notifications.';

    const response = yield call(postIPGoSMS, {
      AJAX_ACTION: 'SMS_Click',
      IsOptIn: sms,
      LoginName: loginName,
      env: GetEnv()
    });

    let policies = globalData.policies;
    
    policies.map((policie, index, array) => {
      policie.sms = sms === 1;
    });

    if(sms !== 1){
      msg = 'You are signed off for SMS notifications.';
    }

    yield call(ShowNotification, {type: 'info', message: msg});
    yield put(toggleLoading());

  } catch (error) {

    yield put(toggleLoading());
    yield call(ShowNotification, {type: 'error', message: error});
    yield put(apiError(error));
  }
}

function* loadPolicyTermData({payload: { policyCode: policyCode, recordPosition: recordPosition, globalData: globalData }}) {

  try {

    yield put(toggleLoading());

    let msg = 'Policy term loaded successfully.';
    // console.log('loaded')

    const response = yield call(postLoadPolTerm, {
      PolCode: policyCode,
      env: GetEnv()
    });

    globalData.policies[recordPosition] = response;

    yield call(ShowNotification, {type: 'info', message: msg});
    yield put(toggleLoading());

  } catch (error) {

    yield put(toggleLoading());
    yield call(ShowNotification, {type: 'error', message: error});
    yield put(apiError(error));
  }
}

function* setGlobalData({payload: { globalData: globalData}}) {

  try {

    yield put(loginSuccess(globalData));

  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(SR_GOGREEN, goGreen);
  yield takeEvery(SR_GOSMS, goSMS);
  yield takeEvery(SR_GETTERMDATA, loadPolicyTermData);
  yield takeEvery(SET_GLOBALDATA, setGlobalData);
}

export default authSaga;
